.black {
  color: #192c39 !important;
}

.white {
  color: #fff !important;
}

.teal {
  color: #00a3ad;
}

.dk-teal {
  color: #0e6e75;
}

.gray-teal {
  color: #425563;
}

.yellow {
  color: #fff1b3;
}

.brtyellow {
  color: #ffd200;
}

.red {
  color: #d72a34;
}

.black-bg {
  background-color: #192c39;
  color: #fff;
}

.white-bg {
  background-color: #fff;
  color: #192c39;
}

.teal-bg {
  background-color: #00a3ad;
  color: #fff;
}

.teal-bg p a {
  color: #e6f6f7 !important;
}

.teal-bg p a:hover {
  color: #0e6e75 !important;
}

.yellow-bg {
  background-color: #fef4d5 !important;
  color: #192c39;
}

.brtyellow-bg {
  background-color: #ffd200 !important;
}

.gray-bg {
  background-color: #efefef;
}

.dark-teal-bg {
  background-color: #0e6e75 !important;
  color: #fff;
}

.dark-teal-bg p a {
  color: #00a3ad !important;
}

.lt-teal2-bg {
  background-color: #ccecee !important;
}

.lt-teal-bg {
  background-color: #e6f6f7 !important;
}

.lt-teal-bg2 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(230, 246, 247)), color-stop(43%, rgba(255, 255, 255, 0.34)), color-stop(65%, rgba(255, 255, 255, 0)));
  background: linear-gradient(to bottom, rgb(230, 246, 247) 0%, rgba(255, 255, 255, 0.34) 43%, rgba(255, 255, 255, 0) 65%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#e6f6f7", endColorstr="#00ffffff",GradientType=0 );
}

.gray-teal-bg {
  background-color: #425563;
  color: #fff;
}

.primary-bg {
  background-color: #ccedef;
}

.secondary-bg {
  background-color: #b3e4e7;
}

.call-teal {
  background-color: rgba(179,228,231,.75);
}

.fade-bg {
  background: #e6f6f7;
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(230, 246, 247)), to(rgb(255, 255, 255)));
  background: linear-gradient(180deg, rgb(230, 246, 247) 0%, rgb(255, 255, 255) 100%);
}

.shadow-1 {
  -webkit-box-shadow: 0 3px 3px rgba(0,0,0,.16);
  box-shadow: 0 3px 3px rgba(0,0,0,.16);
}

.pale-yellow-bg {
  background-color: #fff3be;
}

.full-height {
  height: 100%;
  min-height: 100%;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */

html {
  font-family: "Open Sans",sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  overflow: auto;
  margin: 0;
  min-width: 320px;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

figcaption,
figure {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace,monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0,0,0,0);
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.45em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input {
  overflow: visible;
}

[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

menu {
  display: block;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Open Sans",sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px;
}

select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 3px;
  background: rgba(0,0,0,0);
  line-height: 1;
  cursor: auto;
}

[data-whatinput=mouse] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Open Sans","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small {
  line-height: 0;
  color: #cacaca;
}

h1,
.h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
}

h2,
.h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
}

h3,
.h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
}

h4,
.h4 {
  font-size: 1.56rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
}

h5,
.h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
}

a {
  line-height: inherit;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus {
  color: #000;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 62.5rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: .3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: .5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: .8125rem;
  color: #8a8a8a;
}

cite:before {
  content: "— ";
}

abbr,
abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: .125rem .3125rem .0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas,"Liberation Mono",Courier,monospace;
  font-weight: normal;
  color: #0a0a0a;
}

kbd {
  margin: 0;
  padding: .125rem .25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas,"Liberation Mono",Courier,monospace;
  color: #0a0a0a;
  border-radius: 3px;
}

.subheader {
  margin-top: .2rem;
  margin-bottom: .5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p+.stat {
  margin-top: -1rem;
}

ul.no-bullet,
ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.show-for-print {
  display: none !important;
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: .5rem;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background-color: #fefefe;
  -webkit-box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
  box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: box-shadow .5s,border-color .25s ease-in-out;
  transition: box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

[type=text]:focus,
[type=password]:focus,
[type=date]:focus,
[type=datetime]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=week]:focus,
[type=email]:focus,
[type=number]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=url]:focus,
[type=color]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  -webkit-box-shadow: 0 0 5px #cacaca;
  box-shadow: 0 0 5px #cacaca;
  -webkit-transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: box-shadow .5s,border-color .25s ease-in-out;
  transition: box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #cacaca;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #cacaca;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #cacaca;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #cacaca;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}

input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type=submit],
[type=button] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
}

input[type=search] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

[type=file],
[type=checkbox],
[type=radio] {
  margin: 0 0 1rem;
}

[type=checkbox]+label,
[type=radio]+label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: .5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

[type=checkbox]+label[for],
[type=radio]+label[for] {
  cursor: pointer;
}

label>[type=checkbox],
label>[type=radio] {
  margin-right: .5rem;
}

[type=file] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: .875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a;
}

label.middle {
  margin: 0 0 1rem;
  padding: .5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: .8125rem;
  font-style: italic;
  color: #0a0a0a;
}

.input-group {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.input-group>:first-child {
  border-radius: 3px 0 0 3px;
}

.input-group>:last-child>* {
  border-radius: 0 3px 3px 0;
}

.input-group-label,
.input-group-field,
.input-group-button,
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: .5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca;
}

.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 .1875rem;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: .5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  -webkit-transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: box-shadow .5s,border-color .25s ease-in-out;
  transition: box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s;
}

select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  -webkit-box-shadow: 0 0 5px #cacaca;
  box-shadow: 0 0 5px #cacaca;
  -webkit-transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: border-color .25s ease-in-out,-webkit-box-shadow .5s;
  transition: box-shadow .5s,border-color .25s ease-in-out;
  transition: box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s;
}

select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #c60f13;
  background-color: #f8e6e7;
}

.is-invalid-input:not(:focus)::-webkit-input-placeholder {
  color: #c60f13;
}

.is-invalid-input:not(:focus)::-moz-placeholder {
  color: #c60f13;
}

.is-invalid-input:not(:focus):-ms-input-placeholder {
  color: #c60f13;
}

.is-invalid-input:not(:focus)::-ms-input-placeholder {
  color: #c60f13;
}

.is-invalid-input:not(:focus)::placeholder {
  color: #c60f13;
}

.is-invalid-label {
  color: #c60f13;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: .75rem;
  font-weight: bold;
  color: #c60f13;
}

.form-error.is-visible {
  display: block;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: " ";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.clearfix::after {
  clear: both;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active,
.show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: .85em 1em;
  -webkit-appearance: none;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 3px;
  -webkit-transition: background-color .25s ease-out,color .25s ease-out;
  transition: background-color .25s ease-out,color .25s ease-out;
  font-size: .9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #2ba6cb;
  color: #fefefe;
}

[data-whatinput=mouse] .button {
  outline: 0;
}

.button:hover,
.button:focus {
  background-color: #258dad;
  color: #fefefe;
}

.button.tiny {
  font-size: .6rem;
}

.button.small {
  font-size: .75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #2ba6cb;
  color: #0a0a0a;
}

.button.primary:hover,
.button.primary:focus {
  background-color: #2285a2;
  color: #0a0a0a;
}

.button.secondary {
  background-color: #e9e9e9;
  color: #0a0a0a;
}

.button.secondary:hover,
.button.secondary:focus {
  background-color: #bababa;
  color: #0a0a0a;
}

.button.alert {
  background-color: #c60f13;
  color: #fefefe;
}

.button.alert:hover,
.button.alert:focus {
  background-color: #9e0c0f;
  color: #fefefe;
}

.button.success {
  background-color: #5da423;
  color: #0a0a0a;
}

.button.success:hover,
.button.success:focus {
  background-color: #4a831c;
  color: #0a0a0a;
}

.button.warning {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.warning:hover,
.button.warning:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}

.button.body-font {
  background-color: #222;
  color: #fefefe;
}

.button.body-font:hover,
.button.body-font:focus {
  background-color: #1b1b1b;
  color: #fefefe;
}

.button.header {
  background-color: #222;
  color: #fefefe;
}

.button.header:hover,
.button.header:focus {
  background-color: #1b1b1b;
  color: #fefefe;
}

.button.disabled,
.button[disabled] {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled,
.button.disabled:hover,
.button.disabled:focus,
.button[disabled],
.button[disabled]:hover,
.button[disabled]:focus {
  background-color: #2ba6cb;
  color: #fefefe;
}

.button.disabled.primary,
.button[disabled].primary {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.primary,
.button.disabled.primary:hover,
.button.disabled.primary:focus,
.button[disabled].primary,
.button[disabled].primary:hover,
.button[disabled].primary:focus {
  background-color: #2ba6cb;
  color: #0a0a0a;
}

.button.disabled.secondary,
.button[disabled].secondary {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.secondary,
.button.disabled.secondary:hover,
.button.disabled.secondary:focus,
.button[disabled].secondary,
.button[disabled].secondary:hover,
.button[disabled].secondary:focus {
  background-color: #e9e9e9;
  color: #0a0a0a;
}

.button.disabled.alert,
.button[disabled].alert {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.alert,
.button.disabled.alert:hover,
.button.disabled.alert:focus,
.button[disabled].alert,
.button[disabled].alert:hover,
.button[disabled].alert:focus {
  background-color: #c60f13;
  color: #fefefe;
}

.button.disabled.success,
.button[disabled].success {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.success,
.button.disabled.success:hover,
.button.disabled.success:focus,
.button[disabled].success,
.button[disabled].success:hover,
.button[disabled].success:focus {
  background-color: #5da423;
  color: #0a0a0a;
}

.button.disabled.warning,
.button[disabled].warning {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.warning,
.button.disabled.warning:hover,
.button.disabled.warning:focus,
.button[disabled].warning,
.button[disabled].warning:hover,
.button[disabled].warning:focus {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.disabled.body-font,
.button[disabled].body-font {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.body-font,
.button.disabled.body-font:hover,
.button.disabled.body-font:focus,
.button[disabled].body-font,
.button[disabled].body-font:hover,
.button[disabled].body-font:focus {
  background-color: #222;
  color: #fefefe;
}

.button.disabled.header,
.button[disabled].header {
  opacity: .25;
  cursor: not-allowed;
}

.button.disabled.header,
.button.disabled.header:hover,
.button.disabled.header:focus,
.button[disabled].header,
.button[disabled].header:hover,
.button[disabled].header:focus {
  background-color: #222;
  color: #fefefe;
}

.button.hollow {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.hollow,
.button.hollow:hover,
.button.hollow:focus {
  background-color: rgba(0,0,0,0);
}

.button.hollow.disabled,
.button.hollow.disabled:hover,
.button.hollow.disabled:focus,
.button.hollow[disabled],
.button.hollow[disabled]:hover,
.button.hollow[disabled]:focus {
  background-color: rgba(0,0,0,0);
}

.button.hollow:hover,
.button.hollow:focus {
  border-color: #165366;
  color: #165366;
}

.button.hollow:hover.disabled,
.button.hollow:hover[disabled],
.button.hollow:focus.disabled,
.button.hollow:focus[disabled] {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.hollow.primary {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.hollow.primary:hover,
.button.hollow.primary:focus {
  border-color: #165366;
  color: #165366;
}

.button.hollow.primary:hover.disabled,
.button.hollow.primary:hover[disabled],
.button.hollow.primary:focus.disabled,
.button.hollow.primary:focus[disabled] {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.hollow.secondary {
  border: 1px solid #e9e9e9;
  color: #e9e9e9;
}

.button.hollow.secondary:hover,
.button.hollow.secondary:focus {
  border-color: #757575;
  color: #757575;
}

.button.hollow.secondary:hover.disabled,
.button.hollow.secondary:hover[disabled],
.button.hollow.secondary:focus.disabled,
.button.hollow.secondary:focus[disabled] {
  border: 1px solid #e9e9e9;
  color: #e9e9e9;
}

.button.hollow.alert {
  border: 1px solid #c60f13;
  color: #c60f13;
}

.button.hollow.alert:hover,
.button.hollow.alert:focus {
  border-color: #63080a;
  color: #63080a;
}

.button.hollow.alert:hover.disabled,
.button.hollow.alert:hover[disabled],
.button.hollow.alert:focus.disabled,
.button.hollow.alert:focus[disabled] {
  border: 1px solid #c60f13;
  color: #c60f13;
}

.button.hollow.success {
  border: 1px solid #5da423;
  color: #5da423;
}

.button.hollow.success:hover,
.button.hollow.success:focus {
  border-color: #2f5212;
  color: #2f5212;
}

.button.hollow.success:hover.disabled,
.button.hollow.success:hover[disabled],
.button.hollow.success:focus.disabled,
.button.hollow.success:focus[disabled] {
  border: 1px solid #5da423;
  color: #5da423;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover,
.button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.warning:hover.disabled,
.button.hollow.warning:hover[disabled],
.button.hollow.warning:focus.disabled,
.button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.body-font {
  border: 1px solid #222;
  color: #222;
}

.button.hollow.body-font:hover,
.button.hollow.body-font:focus {
  border-color: #111;
  color: #111;
}

.button.hollow.body-font:hover.disabled,
.button.hollow.body-font:hover[disabled],
.button.hollow.body-font:focus.disabled,
.button.hollow.body-font:focus[disabled] {
  border: 1px solid #222;
  color: #222;
}

.button.hollow.header {
  border: 1px solid #222;
  color: #222;
}

.button.hollow.header:hover,
.button.hollow.header:focus {
  border-color: #111;
  color: #111;
}

.button.hollow.header:hover.disabled,
.button.hollow.header:hover[disabled],
.button.hollow.header:focus.disabled,
.button.hollow.header:focus[disabled] {
  border: 1px solid #222;
  color: #222;
}

.button.clear {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.clear,
.button.clear:hover,
.button.clear:focus {
  background-color: rgba(0,0,0,0);
}

.button.clear.disabled,
.button.clear.disabled:hover,
.button.clear.disabled:focus,
.button.clear[disabled],
.button.clear[disabled]:hover,
.button.clear[disabled]:focus {
  background-color: rgba(0,0,0,0);
}

.button.clear:hover,
.button.clear:focus {
  border-color: #165366;
  color: #165366;
}

.button.clear:hover.disabled,
.button.clear:hover[disabled],
.button.clear:focus.disabled,
.button.clear:focus[disabled] {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.clear,
.button.clear.disabled,
.button.clear[disabled],
.button.clear:hover,
.button.clear:hover.disabled,
.button.clear:hover[disabled],
.button.clear:focus,
.button.clear:focus.disabled,
.button.clear:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.clear.primary {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.clear.primary:hover,
.button.clear.primary:focus {
  border-color: #165366;
  color: #165366;
}

.button.clear.primary:hover.disabled,
.button.clear.primary:hover[disabled],
.button.clear.primary:focus.disabled,
.button.clear.primary:focus[disabled] {
  border: 1px solid #2ba6cb;
  color: #2ba6cb;
}

.button.clear.primary,
.button.clear.primary.disabled,
.button.clear.primary[disabled],
.button.clear.primary:hover,
.button.clear.primary:hover.disabled,
.button.clear.primary:hover[disabled],
.button.clear.primary:focus,
.button.clear.primary:focus.disabled,
.button.clear.primary:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.clear.secondary {
  border: 1px solid #e9e9e9;
  color: #e9e9e9;
}

.button.clear.secondary:hover,
.button.clear.secondary:focus {
  border-color: #757575;
  color: #757575;
}

.button.clear.secondary:hover.disabled,
.button.clear.secondary:hover[disabled],
.button.clear.secondary:focus.disabled,
.button.clear.secondary:focus[disabled] {
  border: 1px solid #e9e9e9;
  color: #e9e9e9;
}

.button.clear.secondary,
.button.clear.secondary.disabled,
.button.clear.secondary[disabled],
.button.clear.secondary:hover,
.button.clear.secondary:hover.disabled,
.button.clear.secondary:hover[disabled],
.button.clear.secondary:focus,
.button.clear.secondary:focus.disabled,
.button.clear.secondary:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.clear.alert {
  border: 1px solid #c60f13;
  color: #c60f13;
}

.button.clear.alert:hover,
.button.clear.alert:focus {
  border-color: #63080a;
  color: #63080a;
}

.button.clear.alert:hover.disabled,
.button.clear.alert:hover[disabled],
.button.clear.alert:focus.disabled,
.button.clear.alert:focus[disabled] {
  border: 1px solid #c60f13;
  color: #c60f13;
}

.button.clear.alert,
.button.clear.alert.disabled,
.button.clear.alert[disabled],
.button.clear.alert:hover,
.button.clear.alert:hover.disabled,
.button.clear.alert:hover[disabled],
.button.clear.alert:focus,
.button.clear.alert:focus.disabled,
.button.clear.alert:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.clear.success {
  border: 1px solid #5da423;
  color: #5da423;
}

.button.clear.success:hover,
.button.clear.success:focus {
  border-color: #2f5212;
  color: #2f5212;
}

.button.clear.success:hover.disabled,
.button.clear.success:hover[disabled],
.button.clear.success:focus.disabled,
.button.clear.success:focus[disabled] {
  border: 1px solid #5da423;
  color: #5da423;
}

.button.clear.success,
.button.clear.success.disabled,
.button.clear.success[disabled],
.button.clear.success:hover,
.button.clear.success:hover.disabled,
.button.clear.success:hover[disabled],
.button.clear.success:focus,
.button.clear.success:focus.disabled,
.button.clear.success:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning:hover,
.button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.clear.warning:hover.disabled,
.button.clear.warning:hover[disabled],
.button.clear.warning:focus.disabled,
.button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning,
.button.clear.warning.disabled,
.button.clear.warning[disabled],
.button.clear.warning:hover,
.button.clear.warning:hover.disabled,
.button.clear.warning:hover[disabled],
.button.clear.warning:focus,
.button.clear.warning:focus.disabled,
.button.clear.warning:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.clear.body-font {
  border: 1px solid #222;
  color: #222;
}

.button.clear.body-font:hover,
.button.clear.body-font:focus {
  border-color: #111;
  color: #111;
}

.button.clear.body-font:hover.disabled,
.button.clear.body-font:hover[disabled],
.button.clear.body-font:focus.disabled,
.button.clear.body-font:focus[disabled] {
  border: 1px solid #222;
  color: #222;
}

.button.clear.body-font,
.button.clear.body-font.disabled,
.button.clear.body-font[disabled],
.button.clear.body-font:hover,
.button.clear.body-font:hover.disabled,
.button.clear.body-font:hover[disabled],
.button.clear.body-font:focus,
.button.clear.body-font:focus.disabled,
.button.clear.body-font:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.clear.header {
  border: 1px solid #222;
  color: #222;
}

.button.clear.header:hover,
.button.clear.header:focus {
  border-color: #111;
  color: #111;
}

.button.clear.header:hover.disabled,
.button.clear.header:hover[disabled],
.button.clear.header:focus.disabled,
.button.clear.header:focus[disabled] {
  border: 1px solid #222;
  color: #222;
}

.button.clear.header,
.button.clear.header.disabled,
.button.clear.header[disabled],
.button.clear.header:hover,
.button.clear.header:hover.disabled,
.button.clear.header:hover[disabled],
.button.clear.header:focus,
.button.clear.header:focus.disabled,
.button.clear.header:focus[disabled] {
  border-color: rgba(0,0,0,0);
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset .4em;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe rgba(0,0,0,0) rgba(0,0,0,0);
  position: relative;
  top: .4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.dropdown.hollow::after {
  border-top-color: #2ba6cb;
}

.button.dropdown.hollow.primary::after {
  border-top-color: #2ba6cb;
}

.button.dropdown.hollow.secondary::after {
  border-top-color: #e9e9e9;
}

.button.dropdown.hollow.alert::after {
  border-top-color: #c60f13;
}

.button.dropdown.hollow.success::after {
  border-top-color: #5da423;
}

.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.body-font::after {
  border-top-color: #222;
}

.button.dropdown.hollow.header::after {
  border-top-color: #222;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover,
a.button:focus {
  text-decoration: none;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}

[data-whatinput=mouse] .close-button {
  outline: 0;
}

.close-button:hover,
.close-button:focus {
  color: #0a0a0a;
}

.close-button.small {
  right: .66rem;
  top: .33em;
  font-size: 1.5em;
  line-height: 1;
}

.close-button,
.close-button.medium {
  right: 1rem;
  top: .5rem;
  font-size: 2em;
  line-height: 1;
}

.button-group {
  margin-bottom: 1rem;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.button-group::before,
.button-group::after {
  display: table;
  content: " ";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.button-group::after {
  clear: both;
}

.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: .9rem;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}

.button-group .button:last-child {
  margin-right: 0;
}

.button-group.tiny .button {
  font-size: .6rem;
}

.button-group.small .button {
  font-size: .75rem;
}

.button-group.large .button {
  font-size: 1.25rem;
}

.button-group.expanded .button {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1 1 0px;
}

.button-group.primary .button {
  background-color: #2ba6cb;
  color: #0a0a0a;
}

.button-group.primary .button:hover,
.button-group.primary .button:focus {
  background-color: #2285a2;
  color: #0a0a0a;
}

.button-group.secondary .button {
  background-color: #e9e9e9;
  color: #0a0a0a;
}

.button-group.secondary .button:hover,
.button-group.secondary .button:focus {
  background-color: #bababa;
  color: #0a0a0a;
}

.button-group.alert .button {
  background-color: #c60f13;
  color: #fefefe;
}

.button-group.alert .button:hover,
.button-group.alert .button:focus {
  background-color: #9e0c0f;
  color: #fefefe;
}

.button-group.success .button {
  background-color: #5da423;
  color: #0a0a0a;
}

.button-group.success .button:hover,
.button-group.success .button:focus {
  background-color: #4a831c;
  color: #0a0a0a;
}

.button-group.warning .button {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button-group.warning .button:hover,
.button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}

.button-group.body-font .button {
  background-color: #222;
  color: #fefefe;
}

.button-group.body-font .button:hover,
.button-group.body-font .button:focus {
  background-color: #1b1b1b;
  color: #fefefe;
}

.button-group.header .button {
  background-color: #222;
  color: #fefefe;
}

.button-group.header .button:hover,
.button-group.header .button:focus {
  background-color: #1b1b1b;
  color: #fefefe;
}

.button-group.stacked,
.button-group.stacked-for-small,
.button-group.stacked-for-medium {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.button-group.stacked .button,
.button-group.stacked-for-small .button,
.button-group.stacked-for-medium .button {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
}

.button-group.stacked .button:last-child,
.button-group.stacked-for-small .button:last-child,
.button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}

.slider {
  position: relative;
  height: .5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: .5rem;
  background-color: #cacaca;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.slider-fill.is-dragging {
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 3px;
  background-color: #2ba6cb;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[data-whatinput=mouse] .slider-handle {
  outline: 0;
}

.slider-handle:hover {
  background-color: #258dad;
}

.slider-handle.is-dragging {
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: .25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: .5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.slider.vertical .slider-fill {
  top: 0;
  width: .5rem;
  max-height: 100%;
}

.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: .875rem;
  font-weight: bold;
  color: #fefefe;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 3px;
  background: #cacaca;
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}

input+.switch-paddle {
  margin: 0;
}

.switch-paddle::after {
  position: absolute;
  top: .25rem;
  left: .25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border-radius: 3px;
  background: #fefefe;
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out;
  content: "";
}

input:checked~.switch-paddle {
  background: #2ba6cb;
}

input:checked~.switch-paddle::after {
  left: 2.25rem;
}

[data-whatinput=mouse] input:focus~.switch-paddle {
  outline: 0;
}

.switch-active,
.switch-inactive {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}

input:checked+label>.switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}

input:checked+label>.switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}

.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: .625rem;
}

.switch.tiny .switch-paddle::after {
  top: .25rem;
  left: .25rem;
  width: 1rem;
  height: 1rem;
}

.switch.tiny input:checked~.switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}

.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: .75rem;
}

.switch.small .switch-paddle::after {
  top: .25rem;
  left: .25rem;
  width: 1.25rem;
  height: 1.25rem;
}

.switch.small input:checked~.switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}

.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1rem;
}

.switch.large .switch-paddle::after {
  top: .25rem;
  left: .25rem;
  width: 2rem;
  height: 2rem;
}

.switch.large input:checked~.switch-paddle::after {
  left: 2.75rem;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

[data-whatinput=mouse] .menu li {
  outline: 0;
}

.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: .7rem 1rem;
}

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}

.menu input {
  display: inline-block;
}

.menu,
.menu.horizontal {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.menu.vertical {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.menu.expanded li {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1 1 0px;
}

.menu.simple {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.menu.simple li+li {
  margin-left: 1rem;
}

.menu.simple a {
  padding: 0;
}

.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}

.menu.icons a {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}

.menu.icon-top a,
.menu.icon-right a,
.menu.icon-bottom a,
.menu.icon-left a {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}

.menu.icon-left li a {
  -ms-flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
}

.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: .25rem;
}

.menu.icon-right li a {
  -ms-flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
}

.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: .25rem;
}

.menu.icon-top li a {
  -ms-flex-flow: column nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column nowrap;
}

.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  margin-bottom: .25rem;
  text-align: center;
}

.menu.icon-bottom li a {
  -ms-flex-flow: column nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column nowrap;
}

.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  margin-bottom: .25rem;
  text-align: center;
}

.menu .is-active>a {
  background: #2ba6cb;
  color: #fefefe;
}

.menu .active>a {
  background: #2ba6cb;
  color: #fefefe;
}

.menu.align-left {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.menu.align-right li {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.menu.align-right li .submenu li {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.menu.align-right.vertical li {
  display: block;
  text-align: right;
}

.menu.align-right.vertical li .submenu li {
  text-align: right;
}

.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}

.menu.align-center li {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.menu.align-center li .submenu li {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.menu .menu-text {
  padding: .7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered>.menu {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.menu-centered>.menu li {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.menu-centered>.menu li .submenu li {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.is-drilldown {
  position: relative;
  overflow: hidden;
}

.is-drilldown li {
  display: block;
}

.is-drilldown.animate-height {
  -webkit-transition: height .5s;
  transition: height .5s;
}

.drilldown a {
  padding: .7rem 1rem;
  background: #fefefe;
}

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  -webkit-transition: -webkit-transform .15s linear;
  transition: -webkit-transform .15s linear;
  transition: transform .15s linear;
  transition: transform .15s linear, -webkit-transform .15s linear;
}

.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.drilldown .is-drilldown-submenu.is-closing {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.drilldown .is-drilldown-submenu a {
  padding: .7rem 1rem;
}

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%;
}

.drilldown .is-drilldown-submenu-parent>a {
  position: relative;
}

.drilldown .is-drilldown-submenu-parent>a::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #888;
}

.drilldown.align-left .is-drilldown-submenu-parent>a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #888;
}

.drilldown.align-right .is-drilldown-submenu-parent>a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: rgba(0,0,0,0) #888 rgba(0,0,0,0) rgba(0,0,0,0);
}

.drilldown .js-drilldown-back>a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: rgba(0,0,0,0) #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: .75rem;
  border-left-width: 0;
}

.accordion-menu li {
  width: 100%;
}

.accordion-menu a {
  padding: .7rem 1rem;
}

.accordion-menu .is-accordion-submenu a {
  padding: .7rem 1rem;
}

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem;
}

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a {
  position: relative;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem;
}

.accordion-menu.align-left .is-accordion-submenu-parent>a::after {
  left: auto;
  right: 1rem;
}

.accordion-menu.align-right .is-accordion-submenu-parent>a::after {
  right: auto;
  left: 1rem;
}

.accordion-menu .is-accordion-submenu-parent[aria-expanded=true]>a::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.is-accordion-submenu-parent {
  position: relative;
}

.has-submenu-toggle>a {
  margin-right: 40px;
}

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
  top: 0;
  bottom: 0;
  margin: auto;
}

.submenu-toggle[aria-expanded=true]::after {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.dropdown.menu>li.opens-left>.is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
  position: relative;
  padding-right: 1.5rem;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
  right: 5px;
  left: auto;
  margin-top: -3px;
}

.dropdown.menu a {
  padding: 1rem 1rem;
}

[data-whatinput=mouse] .dropdown.menu a {
  outline: 0;
}

.dropdown.menu .is-active>a {
  background: rgba(0,0,0,0);
  color: #ccc;
}

.no-js .dropdown.menu ul,
.no-js .expand-all-button,
.no-js .collapse-all-button,
.no-js .close-icon,
.no-js img.back-to-top {
  display: none;
}

.no-js .faq-list .faq-content {
  display: block;
}

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.dropdown.menu.vertical>li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical>li.opens-left>.is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}

.dropdown.menu.vertical>li.opens-right>.is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.dropdown.menu.vertical>li>a::after {
  right: 14px;
}

.dropdown.menu.vertical>li.opens-left>a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: rgba(0,0,0,0) #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
}

.dropdown.menu.vertical>li.opens-right>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #2ba6cb;
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}

.is-dropdown-submenu-parent.opens-inner>.is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left>.is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-right>.is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe;
}

.dropdown .is-dropdown-submenu a {
  padding: .7rem 1rem;
}

.is-dropdown-submenu .is-dropdown-submenu-parent>a::after {
  right: 14px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left>a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: rgba(0,0,0,0) #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #2ba6cb;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}

.is-dropdown-submenu>li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.title-bar {
  padding: .5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.title-bar .menu-icon {
  margin-left: .25rem;
  margin-right: .25rem;
}

.title-bar-left,
.title-bar-right {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.top-bar {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  padding: .5rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}

.top-bar input.button {
  width: auto;
}

.top-bar .top-bar-left,
.top-bar .top-bar-right {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.top-bar-title {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  margin: .5rem 1rem .5rem 0;
}

.top-bar-left,
.top-bar-right {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}

.breadcrumbs::before,
.breadcrumbs::after {
  display: table;
  content: " ";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.breadcrumbs::after {
  clear: both;
}

.breadcrumbs li {
  float: left;
  font-size: .6875rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
}

.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 .75rem;
  opacity: 1;
  content: "/";
  color: #cacaca;
}

.breadcrumbs a {
  color: #2ba6cb;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}

.pagination::before,
.pagination::after {
  display: table;
  content: " ";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.pagination::after {
  clear: both;
}

.pagination li {
  margin-right: .0625rem;
  border-radius: 3px;
  font-size: .875rem;
  display: none;
}

.pagination li:last-child,
.pagination li:first-child {
  display: inline-block;
}

.pagination a,
.pagination button {
  display: block;
  padding: .1875rem .625rem;
  border-radius: 3px;
  color: #0a0a0a;
}

.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}

.pagination .current {
  padding: .1875rem .625rem;
  background: #2ba6cb;
  color: #fefefe;
  cursor: default;
}

.pagination .disabled {
  padding: .1875rem .625rem;
  color: #cacaca;
  cursor: not-allowed;
}

.pagination .disabled:hover {
  background: rgba(0,0,0,0);
}

.pagination .ellipsis::after {
  padding: .1875rem .625rem;
  content: "…";
  color: #0a0a0a;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: .5rem;
  content: "«";
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: .5rem;
  content: "»";
}

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child>:first-child {
  border-radius: 3px 3px 0 0;
}

.accordion-item:last-child>:last-child {
  border-radius: 0 0 3px 3px;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: .75rem;
  line-height: 1;
  color: #2ba6cb;
}

:last-child:not(.is-active)>.accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 3px 3px;
}

.accordion-title:hover,
.accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+";
}

.is-active>.accordion-title::before {
  content: "–";
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a;
}

:last-child>.accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background-color: #fefefe;
  font-size: 1rem;
}

.dropdown-pane.is-opening {
  display: block;
}

.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .5s ease,visibility .5s ease;
  transition: opacity .5s ease,visibility .5s ease;
  background: rgba(254,254,254,.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}

.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}

.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}

.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 12;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput=mouse] .off-canvas {
  outline: 0;
}

.off-canvas.is-transition-push {
  z-index: 12;
}

.off-canvas.is-closed {
  visibility: hidden;
}

.off-canvas.is-transition-overlap {
  z-index: 13;
}

.off-canvas.is-transition-overlap.is-open {
  -webkit-box-shadow: 0 0 10px rgba(10,10,10,.7);
  box-shadow: 0 0 10px rgba(10,10,10,.7);
}

.off-canvas.is-open {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput=mouse] .off-canvas-absolute {
  outline: 0;
}

.off-canvas-absolute.is-transition-push {
  z-index: 12;
}

.off-canvas-absolute.is-closed {
  visibility: hidden;
}

.off-canvas-absolute.is-transition-overlap {
  z-index: 13;
}

.off-canvas-absolute.is-transition-overlap.is-open {
  -webkit-box-shadow: 0 0 10px rgba(10,10,10,.7);
  box-shadow: 0 0 10px rgba(10,10,10,.7);
}

.off-canvas-absolute.is-open {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  -webkit-transform: translateX(-250px);
  transform: translateX(-250px);
}

.off-canvas-content .off-canvas.position-left {
  -webkit-transform: translateX(-250px);
  transform: translateX(-250px);
}

.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.off-canvas-content.is-open-left.has-transition-push {
  -webkit-transform: translateX(250px);
  transform: translateX(250px);
}

.position-left.is-transition-push {
  -webkit-box-shadow: inset -13px 0 20px -13px rgba(10,10,10,.25);
  box-shadow: inset -13px 0 20px -13px rgba(10,10,10,.25);
}

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  -webkit-transform: translateX(250px);
  transform: translateX(250px);
}

.off-canvas-content .off-canvas.position-right {
  -webkit-transform: translateX(250px);
  transform: translateX(250px);
}

.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.off-canvas-content.is-open-right.has-transition-push {
  -webkit-transform: translateX(-250px);
  transform: translateX(-250px);
}

.position-right.is-transition-push {
  -webkit-box-shadow: inset 13px 0 20px -13px rgba(10,10,10,.25);
  box-shadow: inset 13px 0 20px -13px rgba(10,10,10,.25);
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  -webkit-transform: translateY(-250px);
  transform: translateY(-250px);
}

.off-canvas-content .off-canvas.position-top {
  -webkit-transform: translateY(-250px);
  transform: translateY(-250px);
}

.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.off-canvas-content.is-open-top.has-transition-push {
  -webkit-transform: translateY(250px);
  transform: translateY(250px);
}

.position-top.is-transition-push {
  -webkit-box-shadow: inset 0 -13px 20px -13px rgba(10,10,10,.25);
  box-shadow: inset 0 -13px 20px -13px rgba(10,10,10,.25);
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  -webkit-transform: translateY(250px);
  transform: translateY(250px);
}

.off-canvas-content .off-canvas.position-bottom {
  -webkit-transform: translateY(250px);
  transform: translateY(250px);
}

.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.off-canvas-content.is-open-bottom.has-transition-push {
  -webkit-transform: translateY(-250px);
  transform: translateY(-250px);
}

.position-bottom.is-transition-push {
  -webkit-box-shadow: inset 0 13px 20px -13px rgba(10,10,10,.25);
  box-shadow: inset 0 13px 20px -13px rgba(10,10,10,.25);
}

.off-canvas-content {
  -webkit-transform: none;
  transform: none;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.off-canvas-content.has-transition-push {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.off-canvas-content .off-canvas.is-open {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none;
}

.tabs::before,
.tabs::after {
  display: table;
  content: " ";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.tabs::after {
  clear: both;
}

.tabs.vertical>li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple>li>a {
  padding: 0;
}

.tabs.simple>li>a:hover {
  background: rgba(0,0,0,0);
}

.tabs.primary {
  background: #2ba6cb;
}

.tabs.primary>li>a {
  color: #0a0a0a;
}

.tabs.primary>li>a:hover,
.tabs.primary>li>a:focus {
  background: #299ec1;
}

.tabs-title {
  float: left;
}

.tabs-title>a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: .75rem;
  line-height: 1;
  color: #2ba6cb;
}

.tabs-title>a:hover {
  background: #fefefe;
  color: #258faf;
}

.tabs-title>a:focus,
.tabs-title>a[aria-selected=true] {
  background: #e6e6e6;
  color: #2ba6cb;
}

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}

.tabs-panel.is-active {
  display: block;
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10,10,10,.25);
  border-radius: 3px;
  background-color: #fff;
  color: #0a0a0a;
}

.callout>:first-child {
  margin-top: 0;
}

.callout>:last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #def2f8;
  color: #0a0a0a;
}

.callout.secondary {
  background-color: #fcfcfc;
  color: #0a0a0a;
}

.callout.alert {
  background-color: #fcd6d6;
  color: #0a0a0a;
}

.callout.success {
  background-color: #e6f7d9;
  color: #0a0a0a;
}

.callout.warning {
  background-color: #fff3d9;
  color: #0a0a0a;
}

.callout.body-font {
  background-color: #dedede;
  color: #0a0a0a;
}

.callout.header {
  background-color: #dedede;
  color: #0a0a0a;
}

.callout.small {
  padding-top: .5rem;
  padding-right: .5rem;
  padding-bottom: .5rem;
  padding-left: .5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.card {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  background: #fefefe;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a;
}

.card>:last-child {
  margin-bottom: 0;
}

.card-divider {
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  padding: 1rem;
  background: #e6e6e6;
}

.card-divider>:last-child {
  margin-bottom: 0;
}

.card-section {
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  padding: 1rem;
}

.card-section>:last-child {
  margin-bottom: 0;
}

.card-image {
  min-height: 1px;
}

.media-object {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  margin-bottom: 1rem;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.media-object img {
  max-width: none;
}

.media-object-section {
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
}

.media-object-section:first-child {
  padding-right: 1rem;
}

.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}

.media-object-section>:last-child {
  margin-bottom: 0;
}

.media-object-section.main-section {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1 1 0px;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10,10,10,.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput=mouse] .reveal {
  outline: 0;
}

.reveal .column {
  min-width: 0;
}

.reveal>:last-child {
  margin-bottom: 0;
}

.reveal.collapse {
  padding: 0;
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

.reveal.without-overlay {
  position: fixed;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 3px;
}

table thead,
table tbody,
table tfoot {
  border: 1px solid #f1f1f1;
  background-color: #fefefe;
}

table caption {
  padding: .5rem .625rem .625rem;
  font-weight: bold;
}

table thead {
  background: #f8f8f8;
  color: #0a0a0a;
}

table tfoot {
  background: #f1f1f1;
  color: #0a0a0a;
}

table thead tr,
table tfoot tr {
  background: rgba(0,0,0,0);
}

table thead th,
table thead td,
table tfoot th,
table tfoot td {
  padding: .5rem .625rem .625rem;
  font-weight: bold;
  text-align: left;
}

table tbody th,
table tbody td {
  padding: .5rem .625rem .625rem;
}

table tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f1f1f1;
}

table.unstriped tbody {
  background-color: #fefefe;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  background-color: #fefefe;
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f3f3f3;
}

table.hover tfoot tr:hover {
  background-color: #ececec;
}

table.hover tbody tr:hover {
  background-color: #f9f9f9;
}

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec;
}

.table-scroll {
  overflow-x: auto;
}

.table-scroll table {
  width: auto;
}

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: .3em;
  border-radius: 50%;
  font-size: .6rem;
  text-align: center;
  background: #2ba6cb;
  color: #fefefe;
}

.badge.primary {
  background: #2ba6cb;
  color: #0a0a0a;
}

.badge.secondary {
  background: #e9e9e9;
  color: #0a0a0a;
}

.badge.alert {
  background: #c60f13;
  color: #fefefe;
}

.badge.success {
  background: #5da423;
  color: #0a0a0a;
}

.badge.warning {
  background: #ffae00;
  color: #0a0a0a;
}

.badge.body-font {
  background: #222;
  color: #fefefe;
}

.badge.header {
  background: #222;
  color: #fefefe;
}

.label {
  display: inline-block;
  padding: .33333rem .5rem;
  border-radius: 3px;
  font-size: .8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #2ba6cb;
  color: #fefefe;
}

.label.primary {
  background: #2ba6cb;
  color: #0a0a0a;
}

.label.secondary {
  background: #e9e9e9;
  color: #0a0a0a;
}

.label.alert {
  background: #c60f13;
  color: #fefefe;
}

.label.success {
  background: #5da423;
  color: #0a0a0a;
}

.label.warning {
  background: #ffae00;
  color: #0a0a0a;
}

.label.body-font {
  background: #222;
  color: #fefefe;
}

.label.header {
  background: #222;
  color: #fefefe;
}

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  background-color: #cacaca;
}

.progress.primary .progress-meter {
  background-color: #2ba6cb;
}

.progress.secondary .progress-meter {
  background-color: #e9e9e9;
}

.progress.alert .progress-meter {
  background-color: #c60f13;
}

.progress.success .progress-meter {
  background-color: #5da423;
}

.progress.warning .progress-meter {
  background-color: #ffae00;
}

.progress.body-font .progress-meter {
  background-color: #222;
}

.progress.header .progress-meter {
  background-color: #222;
}

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #2ba6cb;
  border-radius: 3px;
}

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: .75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap;
  border-radius: 3px;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + .6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: .75rem;
  border-radius: 3px;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe;
}

.tooltip::before {
  position: absolute;
}

.tooltip.bottom::before {
  display: block;
  width: 0;
  height: 0;
  border: inset .75rem;
  content: "";
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) #0a0a0a;
  bottom: 100%;
}

.tooltip.bottom.align-center::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset .75rem;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a rgba(0,0,0,0) rgba(0,0,0,0);
  top: 100%;
  bottom: auto;
}

.tooltip.top.align-center::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset .75rem;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #0a0a0a;
  left: 100%;
}

.tooltip.left.align-center::before {
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset .75rem;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: rgba(0,0,0,0) #0a0a0a rgba(0,0,0,0) rgba(0,0,0,0);
  right: 100%;
  left: auto;
}

.tooltip.right.align-center::before {
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tooltip.align-top::before {
  bottom: auto;
  top: 10%;
}

.tooltip.align-bottom::before {
  bottom: 10%;
  top: auto;
}

.tooltip.align-left::before {
  left: 10%;
  right: auto;
}

.tooltip.align-right::before {
  left: auto;
  right: 10%;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10,10,10,.5);
  color: #fefefe;
}

.orbit-previous,
.orbit-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe;
}

[data-whatinput=mouse] .orbit-previous,
[data-whatinput=mouse] .orbit-next {
  outline: 0;
}

.orbit-previous:hover,
.orbit-next:hover,
.orbit-previous:active,
.orbit-next:active,
.orbit-previous:focus,
.orbit-next:focus {
  background-color: rgba(10,10,10,.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: .8rem;
  margin-bottom: .8rem;
  text-align: center;
}

[data-whatinput=mouse] .orbit-bullets {
  outline: 0;
}

.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: .1rem;
  border-radius: 50%;
  background-color: #cacaca;
}

.orbit-bullets button:hover {
  background-color: #8a8a8a;
}

.orbit-bullets button.is-active {
  background-color: #8a8a8a;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(10,10,10,.2);
  box-shadow: 0 0 0 1px rgba(10,10,10,.2);
  line-height: 0;
}

a.thumbnail {
  -webkit-transition: -webkit-box-shadow 200ms ease-out;
  transition: -webkit-box-shadow 200ms ease-out;
  transition: box-shadow 200ms ease-out;
  transition: box-shadow 200ms ease-out, -webkit-box-shadow 200ms ease-out;
}

a.thumbnail:hover,
a.thumbnail:focus {
  -webkit-box-shadow: 0 0 6px 1px rgba(43,166,203,.5);
  box-shadow: 0 0 6px 1px rgba(43,166,203,.5);
}

a.thumbnail image {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sticky-container {
  position: relative;
}

.sticky {
  position: relative;
  z-index: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.sticky.is-stuck.is-at-top {
  top: 0;
}

.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}

.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

.grid-container {
  padding-right: .9575rem;
  padding-left: .9575rem;
  max-width: 80rem;
  margin: 0 auto;
}

.grid-container.fluid {
  padding-right: .9375rem;
  padding-left: .9375rem;
  max-width: 100%;
  margin: 0 auto;
}

.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin: 0 auto;
}

.grid-x {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-flow: row wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
}

.cell {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}

.cell.auto {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1 1 0px;
}

.cell.shrink {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}

.grid-x>.auto {
  width: auto;
}

.grid-x>.shrink {
  width: auto;
}

.grid-x>.small-shrink,
.grid-x>.small-full,
.grid-x>.small-1,
.grid-x>.small-2,
.grid-x>.small-3,
.grid-x>.small-4,
.grid-x>.small-5,
.grid-x>.small-6,
.grid-x>.small-7,
.grid-x>.small-8,
.grid-x>.small-9,
.grid-x>.small-10,
.grid-x>.small-11,
.grid-x>.small-12 {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.grid-x>.small-1 {
  width: 8.33333%;
}

.grid-x>.small-2 {
  width: 16.66667%;
}

.grid-x>.small-3 {
  width: 25%;
}

.grid-x>.small-4 {
  width: 33.33333%;
}

.grid-x>.small-5 {
  width: 41.66667%;
}

.grid-x>.small-6 {
  width: 50%;
}

.grid-x>.small-7 {
  width: 58.33333%;
}

.grid-x>.small-8 {
  width: 66.66667%;
}

.grid-x>.small-9 {
  width: 75%;
}

.grid-x>.small-10 {
  width: 83.33333%;
}

.grid-x>.small-11 {
  width: 91.66667%;
}

.grid-x>.small-12 {
  width: 100%;
}

.grid-margin-x:not(.grid-x)>.cell {
  width: auto;
}

.grid-margin-y:not(.grid-y)>.cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.grid-margin-x>.cell {
  width: calc(100% - 1.875rem);
  margin-left: .9375rem;
  margin-right: .9375rem;
}

.grid-margin-x>.auto {
  width: auto;
}

.grid-margin-x>.shrink {
  width: auto;
}

.grid-margin-x>.small-1 {
  width: calc(8.33333% - 1.875rem);
}

.grid-margin-x>.small-2 {
  width: calc(16.66667% - 1.875rem);
}

.grid-margin-x>.small-3 {
  width: calc(25% - 1.875rem);
}

.grid-margin-x>.small-4 {
  width: calc(33.33333% - 1.875rem);
}

.grid-margin-x>.small-5 {
  width: calc(41.66667% - 1.875rem);
}

.grid-margin-x>.small-6 {
  width: calc(50% - 1.875rem);
}

.grid-margin-x>.small-7 {
  width: calc(58.33333% - 1.875rem);
}

.grid-margin-x>.small-8 {
  width: calc(66.66667% - 1.875rem);
}

.grid-margin-x>.small-9 {
  width: calc(75% - 1.875rem);
}

.grid-margin-x>.small-10 {
  width: calc(83.33333% - 1.875rem);
}

.grid-margin-x>.small-11 {
  width: calc(91.66667% - 1.875rem);
}

.grid-margin-x>.small-12 {
  width: calc(100% - 1.875rem);
}

.grid-padding-x .grid-padding-x {
  margin-right: -0.7375rem;
  margin-left: -0.7375rem;
}

.grid-container:not(.full)>.grid-padding-x {
  margin-right: -0.7375rem;
  margin-left: -0.7375rem;
}

.grid-padding-x>.cell {
  padding-right: .7375rem;
  padding-left: .7375rem;
}

.small-up-1>.cell {
  width: 100%;
}

.small-up-2>.cell {
  width: 50%;
}

.small-up-3>.cell {
  width: 33.33333%;
}

.small-up-4>.cell {
  width: 25%;
}

.small-up-5>.cell {
  width: 20%;
}

.small-up-6>.cell {
  width: 16.66667%;
}

.small-up-7>.cell {
  width: 14.28571%;
}

.small-up-8>.cell {
  width: 12.5%;
}

.grid-margin-x.small-up-1>.cell {
  width: calc(100% - 1.875rem);
}

.grid-margin-x.small-up-2>.cell {
  width: calc(50% - 1.875rem);
}

.grid-margin-x.small-up-3>.cell {
  width: calc(33.33333% - 1.875rem);
}

.grid-margin-x.small-up-4>.cell {
  width: calc(25% - 1.875rem);
}

.grid-margin-x.small-up-5>.cell {
  width: calc(20% - 1.875rem);
}

.grid-margin-x.small-up-6>.cell {
  width: calc(16.66667% - 1.875rem);
}

.grid-margin-x.small-up-7>.cell {
  width: calc(14.28571% - 1.875rem);
}

.grid-margin-x.small-up-8>.cell {
  width: calc(12.5% - 1.875rem);
}

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse>.cell {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse>.small-1 {
  width: 8.33333%;
}

.small-margin-collapse>.small-2 {
  width: 16.66667%;
}

.small-margin-collapse>.small-3 {
  width: 25%;
}

.small-margin-collapse>.small-4 {
  width: 33.33333%;
}

.small-margin-collapse>.small-5 {
  width: 41.66667%;
}

.small-margin-collapse>.small-6 {
  width: 50%;
}

.small-margin-collapse>.small-7 {
  width: 58.33333%;
}

.small-margin-collapse>.small-8 {
  width: 66.66667%;
}

.small-margin-collapse>.small-9 {
  width: 75%;
}

.small-margin-collapse>.small-10 {
  width: 83.33333%;
}

.small-margin-collapse>.small-11 {
  width: 91.66667%;
}

.small-margin-collapse>.small-12 {
  width: 100%;
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-padding-collapse>.cell {
  padding-right: 0;
  padding-left: 0;
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x>.small-offset-0 {
  margin-left: calc(0% + .9375rem);
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.grid-margin-x>.small-offset-1 {
  margin-left: calc(8.33333% + .9375rem);
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.grid-margin-x>.small-offset-2 {
  margin-left: calc(16.66667% + .9375rem);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x>.small-offset-3 {
  margin-left: calc(25% + .9375rem);
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.grid-margin-x>.small-offset-4 {
  margin-left: calc(33.33333% + .9375rem);
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.grid-margin-x>.small-offset-5 {
  margin-left: calc(41.66667% + .9375rem);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x>.small-offset-6 {
  margin-left: calc(50% + .9375rem);
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.grid-margin-x>.small-offset-7 {
  margin-left: calc(58.33333% + .9375rem);
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.grid-margin-x>.small-offset-8 {
  margin-left: calc(66.66667% + .9375rem);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x>.small-offset-9 {
  margin-left: calc(75% + .9375rem);
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.grid-margin-x>.small-offset-10 {
  margin-left: calc(83.33333% + .9375rem);
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.grid-margin-x>.small-offset-11 {
  margin-left: calc(91.66667% + .9375rem);
}

.grid-y {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-flow: column nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column nowrap;
}

.grid-y>.cell {
  width: auto;
}

.grid-y>.auto {
  height: auto;
}

.grid-y>.shrink {
  height: auto;
}

.grid-y>.small-shrink,
.grid-y>.small-full,
.grid-y>.small-1,
.grid-y>.small-2,
.grid-y>.small-3,
.grid-y>.small-4,
.grid-y>.small-5,
.grid-y>.small-6,
.grid-y>.small-7,
.grid-y>.small-8,
.grid-y>.small-9,
.grid-y>.small-10,
.grid-y>.small-11,
.grid-y>.small-12 {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.grid-y>.small-1 {
  height: 8.33333%;
}

.grid-y>.small-2 {
  height: 16.66667%;
}

.grid-y>.small-3 {
  height: 25%;
}

.grid-y>.small-4 {
  height: 33.33333%;
}

.grid-y>.small-5 {
  height: 41.66667%;
}

.grid-y>.small-6 {
  height: 50%;
}

.grid-y>.small-7 {
  height: 58.33333%;
}

.grid-y>.small-8 {
  height: 66.66667%;
}

.grid-y>.small-9 {
  height: 75%;
}

.grid-y>.small-10 {
  height: 83.33333%;
}

.grid-y>.small-11 {
  height: 91.66667%;
}

.grid-y>.small-12 {
  height: 100%;
}

.grid-padding-y .grid-padding-y {
  margin-top: -0.9375rem;
  margin-bottom: -0.9375rem;
}

.grid-padding-y>.cell {
  padding-top: .9375rem;
  padding-bottom: .9375rem;
}

.grid-margin-y {
  margin-top: -0.9375rem;
  margin-bottom: -0.9375rem;
}

.grid-margin-y>.cell {
  height: calc(100% - 1.875rem);
  margin-top: .9375rem;
  margin-bottom: .9375rem;
}

.grid-margin-y>.auto {
  height: auto;
}

.grid-margin-y>.shrink {
  height: auto;
}

.grid-margin-y>.small-1 {
  height: calc(8.33333% - 1.875rem);
}

.grid-margin-y>.small-2 {
  height: calc(16.66667% - 1.875rem);
}

.grid-margin-y>.small-3 {
  height: calc(25% - 1.875rem);
}

.grid-margin-y>.small-4 {
  height: calc(33.33333% - 1.875rem);
}

.grid-margin-y>.small-5 {
  height: calc(41.66667% - 1.875rem);
}

.grid-margin-y>.small-6 {
  height: calc(50% - 1.875rem);
}

.grid-margin-y>.small-7 {
  height: calc(58.33333% - 1.875rem);
}

.grid-margin-y>.small-8 {
  height: calc(66.66667% - 1.875rem);
}

.grid-margin-y>.small-9 {
  height: calc(75% - 1.875rem);
}

.grid-margin-y>.small-10 {
  height: calc(83.33333% - 1.875rem);
}

.grid-margin-y>.small-11 {
  height: calc(91.66667% - 1.875rem);
}

.grid-margin-y>.small-12 {
  height: calc(100% - 1.875rem);
}

.grid-frame {
  overflow: hidden;
  position: relative;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  max-height: 100%;
}

.cell-block-container>.grid-x {
  max-height: 100%;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
  height: 100vh;
}

.cell .grid-y.grid-frame {
  height: 100%;
}

.grid-margin-y {
  margin-top: -0.9375rem;
  margin-bottom: -0.9375rem;
}

.grid-margin-y>.cell {
  height: calc(100% - 1.875rem);
  margin-top: .9375rem;
  margin-bottom: .9375rem;
}

.grid-margin-y>.auto {
  height: auto;
}

.grid-margin-y>.shrink {
  height: auto;
}

.grid-margin-y>.small-1 {
  height: calc(8.33333% - 1.875rem);
}

.grid-margin-y>.small-2 {
  height: calc(16.66667% - 1.875rem);
}

.grid-margin-y>.small-3 {
  height: calc(25% - 1.875rem);
}

.grid-margin-y>.small-4 {
  height: calc(33.33333% - 1.875rem);
}

.grid-margin-y>.small-5 {
  height: calc(41.66667% - 1.875rem);
}

.grid-margin-y>.small-6 {
  height: calc(50% - 1.875rem);
}

.grid-margin-y>.small-7 {
  height: calc(58.33333% - 1.875rem);
}

.grid-margin-y>.small-8 {
  height: calc(66.66667% - 1.875rem);
}

.grid-margin-y>.small-9 {
  height: calc(75% - 1.875rem);
}

.grid-margin-y>.small-10 {
  height: calc(83.33333% - 1.875rem);
}

.grid-margin-y>.small-11 {
  height: calc(91.66667% - 1.875rem);
}

.grid-margin-y>.small-12 {
  height: calc(100% - 1.875rem);
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.875rem);
}

.align-right {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.align-center {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.align-justify {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.align-spaced {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-right.vertical.menu>li>a {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.align-center.vertical.menu>li>a {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.align-top {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.align-self-top {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-bottom {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}

.align-self-bottom {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-middle {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.align-self-middle {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.align-stretch {
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.align-center-middle {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.small-order-1 {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.small-order-2 {
  -ms-flex-order: 2;
  -webkit-box-ordinal-group: 3;
  order: 2;
}

.small-order-3 {
  -ms-flex-order: 3;
  -webkit-box-ordinal-group: 4;
  order: 3;
}

.small-order-4 {
  -ms-flex-order: 4;
  -webkit-box-ordinal-group: 5;
  order: 4;
}

.small-order-5 {
  -ms-flex-order: 5;
  -webkit-box-ordinal-group: 6;
  order: 5;
}

.small-order-6 {
  -ms-flex-order: 6;
  -webkit-box-ordinal-group: 7;
  order: 6;
}

.flex-container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}

.flex-child-auto {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.flex-child-grow {
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
}

.flex-child-shrink {
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
}

.flex-dir-row {
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.flex-dir-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.flex-dir-column {
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.flex-dir-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fefefe;
  -webkit-box-shadow: 0 7px 0 #fefefe,0 14px 0 #fefefe;
  box-shadow: 0 7px 0 #fefefe,0 14px 0 #fefefe;
  content: "";
}

.menu-icon:hover::after {
  background: #cacaca;
  -webkit-box-shadow: 0 7px 0 #cacaca,0 14px 0 #cacaca;
  box-shadow: 0 7px 0 #cacaca,0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  -webkit-box-shadow: 0 7px 0 #0a0a0a,0 14px 0 #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a,0 14px 0 #0a0a0a;
  content: "";
}

.menu-icon.dark:hover::after {
  background: #8a8a8a;
  -webkit-box-shadow: 0 7px 0 #8a8a8a,0 14px 0 #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a,0 14px 0 #8a8a8a;
}

.slide-in-down.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-down.mui-enter.mui-enter-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-up.mui-enter.mui-enter-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-in-right.mui-enter.mui-enter-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-down.mui-leave.mui-leave-active {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-up.mui-leave.mui-leave-active {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slide-out-left.mui-leave.mui-leave-active {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  opacity: 1;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(-90deg);
  transform: perspective(2000px) rotateX(-90deg);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-right.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(-90deg);
  transform: perspective(2000px) rotateY(-90deg);
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-bottom.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(90deg);
  transform: perspective(2000px) rotateX(90deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-left.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(90deg);
  transform: perspective(2000px) rotateY(90deg);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-x.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(-90deg);
  transform: perspective(2000px) rotateX(-90deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-y.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(-90deg);
  transform: perspective(2000px) rotateY(-90deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-out-from-top.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(-90deg);
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-right.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(-90deg);
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.hinge-out-from-bottom.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(90deg);
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-left.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(90deg);
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.hinge-out-from-middle-x.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(-90deg);
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-middle-y.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
  transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(-90deg);
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.scale-in-up.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.scale-in-up.mui-enter.mui-enter-active {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.scale-in-down.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.scale-in-down.mui-enter.mui-enter-active {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.scale-out-up.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.scale-out-up.mui-leave.mui-leave-active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
}

.scale-out-down.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.scale-out-down.mui-leave.mui-leave-active {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
}

.spin-in.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.spin-in.mui-enter.mui-enter-active {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  opacity: 1;
}

.spin-out.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.spin-out.mui-leave.mui-leave-active {
  -webkit-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  opacity: 0;
}

.spin-in-ccw.mui-enter {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(0.75turn);
  transform: rotate(0.75turn);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 0;
}

.spin-in-ccw.mui-enter.mui-enter-active {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  opacity: 1;
}

.spin-out-ccw.mui-leave {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  opacity: 1;
}

.spin-out-ccw.mui-leave.mui-leave-active {
  -webkit-transform: rotate(-0.75turn);
  transform: rotate(-0.75turn);
  opacity: 0;
}

.slow {
  -webkit-transition-duration: 750ms !important;
  transition-duration: 750ms !important;
}

.fast {
  -webkit-transition-duration: 250ms !important;
  transition-duration: 250ms !important;
}

.linear {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.ease {
  -webkit-transition-timing-function: ease !important;
  transition-timing-function: ease !important;
}

.ease-in {
  -webkit-transition-timing-function: ease-in !important;
  transition-timing-function: ease-in !important;
}

.ease-out {
  -webkit-transition-timing-function: ease-out !important;
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  -webkit-transition-timing-function: ease-in-out !important;
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  -webkit-transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  -webkit-transition-delay: 300ms !important;
  transition-delay: 300ms !important;
}

.long-delay {
  -webkit-transition-delay: 700ms !important;
  transition-delay: 700ms !important;
}

.shake {
  -webkit-animation-name: shake-7;
  animation-name: shake-7;
}

@-webkit-keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    -webkit-transform: translateX(7%);
    transform: translateX(7%);
  }

  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    -webkit-transform: translateX(-7%);
    transform: translateX(-7%);
  }
}

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    -webkit-transform: translateX(7%);
    transform: translateX(7%);
  }

  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    -webkit-transform: translateX(-7%);
    transform: translateX(-7%);
  }
}

.spin-cw {
  -webkit-animation-name: spin-cw-1turn;
  animation-name: spin-cw-1turn;
}

@-webkit-keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.spin-ccw {
  -webkit-animation-name: spin-cw-1turn;
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.wiggle {
  -webkit-animation-name: wiggle-7deg;
  animation-name: wiggle-7deg;
}

@-webkit-keyframes wiggle-7deg {
  40%, 50%, 60% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }

  35%, 45%, 55%, 65% {
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }

  0%, 30%, 70%, 100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }

  35%, 45%, 55%, 65% {
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }

  0%, 30%, 70%, 100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.slow {
  -webkit-animation-duration: 750ms !important;
  animation-duration: 750ms !important;
}

.fast {
  -webkit-animation-duration: 250ms !important;
  animation-duration: 250ms !important;
}

.linear {
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
}

.ease {
  -webkit-animation-timing-function: ease !important;
  animation-timing-function: ease !important;
}

.ease-in {
  -webkit-animation-timing-function: ease-in !important;
  animation-timing-function: ease-in !important;
}

.ease-out {
  -webkit-animation-timing-function: ease-out !important;
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  -webkit-animation-timing-function: ease-in-out !important;
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  -webkit-animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  -webkit-animation-delay: 300ms !important;
  animation-delay: 300ms !important;
}

.long-delay {
  -webkit-animation-delay: 700ms !important;
  animation-delay: 700ms !important;
}

body {
  font-family: "din-2014",sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "din-2014",sans-serif;
  font-weight: 700;
  font-style: normal;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 52px;
  color: #00747e;
}

h2 {
  font-size: 24px;
  line-height: 42px;
  color: #425563;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  color: #192c39;
}

h4 {
  margin-bottom: 0;
}

ul li {
  margin: 5px 0;
  font-size: 18px !important;
}

p {
  font-family: "din-2014",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  padding: 9;
}

p a {
  color: #0e6e75;
  -webkit-transition: .2s;
  transition: .2s;
}

p a:hover {
  color: #0e6e75;
  -webkit-transition: .2s;
  transition: .2s;
  text-decoration: underline;
}

small {
  font-family: "din-2014",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: block;
}

.button-yellow {
  padding: 12px 16px;
  font-size: 20px;
  background-color: #ffd200;
  border: 1px solid #edc50b;
  border-radius: 4px;
  color: #192c39 !important;
  -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,.2);
  box-shadow: 0px 2px 4px rgba(0,0,0,.2);
  text-transform: capitalize;
  -webkit-transition: .2s;
  transition: .2s;
  text-decoration: none;
  font-weight: 700;
}

.button-yellow::after {
  content: ">";
  color: #192c39;
  padding-left: 8px;
  position: relative;
  top: 3px;
  font-size: 24px;
}

.button-yellow:hover {
  background-color: #fdd76d;
  -webkit-box-shadow: inset 3px 3px 0px #fff1b3,inset -3px -3px 0px #fff1b3,0px 2px 4px rgba(0,0,0,.2);
  box-shadow: inset 3px 3px 0px #fff1b3,inset -3px -3px 0px #fff1b3,0px 2px 4px rgba(0,0,0,.2);
  color: #192c39;
  border-color: #fdd76d;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 30px 4px 30px 4px;
  -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,.2);
  box-shadow: 0px 2px 4px rgba(0,0,0,.2);
}

.button-yellow:active {
  background-color: #edc60b;
  -webkit-box-shadow: inset 3px 3px 0px #fff1b3,inset -3px -3px 0px #fff1b3,0px 2px 4px rgba(0,0,0,.2);
  box-shadow: inset 3px 3px 0px #fff1b3,inset -3px -3px 0px #fff1b3,0px 2px 4px rgba(0,0,0,.2);
  color: #192c39;
}

.button-yellow:focus {
  background-color: #edc60b;
  -webkit-box-shadow: inset 3px 3px 0px #fff1b3,inset -3px -3px 0px #fff1b3,0px 2px 4px rgba(0,0,0,.2);
  box-shadow: inset 3px 3px 0px #fff1b3,inset -3px -3px 0px #fff1b3,0px 2px 4px rgba(0,0,0,.2);
  color: #192c39;
}

.button-teal {
  padding: 10px 16px;
  display: inline-block;
  font-size: 20px;
  background-color: #0e6e75;
  border: 1px solid #088b96;
  border-radius: 4px;
  color: #fff !important;
  -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,.2);
  box-shadow: 0px 2px 4px rgba(0,0,0,.2);
  text-transform: capitalize;
  -webkit-transition: .2s;
  transition: .2s;
  text-decoration: none;
  font-weight: 700;
}

.button-teal::after {
  content: ">";
  color: #fff;
  padding-left: 8px;
  position: relative;
  top: 3px;
  font-size: 24px;
}

.button-teal:hover,
.button-teal:focus {
  font-size: 20px;
  background-color: #088b96;
  border-color: #ccedef;
  border-radius: 30px 4px 30px 4px;
  -webkit-box-shadow: 2px 1px 4px 0px rgba(0,0,0,.2);
  box-shadow: 2px 1px 4px 0px rgba(0,0,0,.2);
  color: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

.button-teal:active {
  background-color: #00747e;
  -webkit-box-shadow: inset 3px 3px 0px #088b96,inset -3px -3px 0px #088b96,0px 2px 4px rgba(0,0,0,.2);
  box-shadow: inset 3px 3px 0px #088b96,inset -3px -3px 0px #088b96,0px 2px 4px rgba(0,0,0,.2);
  color: #fff;
}

ul li ul li::marker {
  content: "- ";
}

.navigation {
  background-color: hsla(0,0%,100%,.4);
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(255, 255, 255)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=0 );
}

.patient .logo img {
  max-width: 200px;
  padding: 8px 0;
}

.patient .topbar {
  padding: 0;
  margin-top: 24px;
  margin-bottom: 12px;
}

.patient .topbar li {
  list-style-type: none;
  display: inline-block;
  margin: 0 10px;
}

.patient .topbar li a {
  color: #192c39;
  -webkit-transition: .2s;
  transition: .2s;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
}

.patient .topbar li a:hover {
  color: #00a3ad;
  -webkit-transition: .2s;
  transition: .2s;
}

.patient nav ul {
  padding: 0;
  margin: 0;
}

.patient nav ul li {
  display: inline-block;
  margin: 0;
}

.patient nav ul li .nav-item {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #192c39;
  margin: 0;
  padding: 13px 27px;
}

.patient nav ul li .nav-item:hover {
  background-color: #ccedef;
  color: #192c39;
}

.patient nav ul li .nav-item.active {
  background-color: #ccedef;
}

.patient #mobileNav {
  background-color: #ccedef;
  -webkit-box-shadow: inset 0px 2px 4px rgba(0,0,0,.16);
  box-shadow: inset 0px 2px 4px rgba(0,0,0,.16);
  display: none;
}

.patient #mobileNav ul {
  padding: 0;
  margin: 0;
}

.patient #mobileNav ul li {
  margin-bottom: 0;
  margin-top: 0;
}

.patient #mobileNav ul.bold li {
  margin: 0;
}

.patient #mobileNav ul.bold li a {
  font-weight: 700;
}

.patient #mobileNav ul li {
  list-style-type: none;
  padding: 0 40px;
}

.patient #mobileNav ul li a {
  display: block;
  font-size: 18px;
  color: #192c39;
  padding: 15px 0;
}

.hcp .dropdown.menu>li.is-dropdown-submenu-parent>a::after {
  border-color: #192c39 rgba(0,0,0,0) rgba(0,0,0,0);
}

.hcp .dropdown.menu>li.is-dropdown-submenu-parent:hover>a::after,
.hcp .dropdown.menu>li.is-dropdown-submenu-parent.active>a::after {
  border-color: #fff rgba(0,0,0,0) rgba(0,0,0,0);
}

.hcp .logo img {
  max-width: 200px;
  padding: 8px 0;
}

.hcp .topbar {
  padding: 0;
  margin: 0;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.hcp .topbar li {
  list-style-type: none;
  display: inline-block;
  margin: 0 10px;
}

.hcp .topbar li a {
  color: #192c39;
  -webkit-transition: .2s;
  transition: .2s;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
}

.hcp .topbar li a:hover {
  color: #00a3ad !important;
  background-color: none !important;
  -webkit-transition: .2s;
  transition: .2s;
}

.hcp nav .dropdown.menu>li.opens-right>.is-dropdown-submenu {
  min-width: 100%;
}

.hcp nav ul:not(.submenu) {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e6f6f7;
  -webkit-box-shadow: 0 3px 3px rgba(0,0,0,.16);
  box-shadow: 0 3px 3px rgba(0,0,0,.16);
}

.hcp nav ul:not(.submenu) li {
  display: inline-block;
  margin: 0;
}

.hcp nav ul:not(.submenu) li a {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  display: block;
  padding: 13px 1rem;
  color: #192c39;
  margin: 0;
}

.hcp nav ul:not(.submenu) li a:hover {
  background-color: #00a3ad;
  color: #fff;
  -webkit-box-shadow: inset 0px 4px 0px #ffd200,inset 0px 5px 2px rgba(0,0,0,.4);
  box-shadow: inset 0px 4px 0px #ffd200,inset 0px 5px 2px rgba(0,0,0,.4);
}

.hcp nav ul:not(.submenu) li a.active {
  background-color: #00a3ad;
  color: #fff;
  -webkit-box-shadow: inset 0px 4px 0px #ffd200,inset 0px 5px 2px rgba(0,0,0,.4);
  box-shadow: inset 0px 4px 0px #ffd200,inset 0px 5px 2px rgba(0,0,0,.4);
}

.hcp nav ul:not(.submenu) li:hover {
  background-color: #00a3ad;
}

.hcp nav ul:not(.submenu) li:not(.is-submenu-item) {
  width: 14.28%;
}

.hcp nav ul:not(.submenu) li.is-active a {
  background: #00a3ad;
  color: #fff;
}

.hcp nav ul.submenu {
  background-color: #088b96;
}

.hcp nav ul.submenu li a {
  color: #fff;
}

.hcp nav ul:not(.submenu) li.active a {
  position: relative;
  background: #00a3ad;
  color: #fff;
}

.hcp nav ul:not(.submenu) li.active a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 4px;
  background: #ffd200;
}

.hcp nav .request {
  background-color: #ffd200;
}

.hcp #mobileNav {
  background-color: #ccedef;
  -webkit-box-shadow: inset 0px 2px 4px rgba(0,0,0,.16);
  box-shadow: inset 0px 2px 4px rgba(0,0,0,.16);
  display: none;
}

.hcp #mobileNav ul {
  margin: 0;
}

.hcp #mobileNav ul li {
  margin-bottom: 0;
  margin-top: 0;
}

.hcp #mobileNav ul.bold li {
  margin: 0;
}

.hcp #mobileNav ul.bold li a {
  font-weight: 700;
}

.hcp #mobileNav ul li {
  list-style-type: none;
  padding: 0 40px;
}

.hcp #mobileNav ul li a {
  display: block;
  font-size: 18px;
  color: #192c39;
  padding: 15px 0;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
  margin-right: 5px;
}

.signup-nav {
  padding: 6px 12px;
  font-size: 20px;
  background-color: #ffd200;
  border: 1px solid #edc50b;
  border-radius: 4px;
  color: #192c39 !important;
  margin: 0px 10px;
  text-transform: capitalize;
  -webkit-transition: .2s;
  transition: .2s;
  text-decoration: none;
  font-weight: 700;
}

.signup-nav:hover {
  background-color: none !important;
}

.hero-hcp {
  padding: 24px;
}

.hero-hcp.bg-img-top {
  background-position-y: top;
}

.hero-hcp.bg-img-center {
  background-position-y: center;
}

.hero-hcp .hero-card {
  background: #fff;
  border-radius: 4px;
  padding: 24px;
  overflow: hidden;
}

.hero-hcp .hero-card h3 {
  margin-bottom: 24px;
}

.hero-hcp .hero-card p {
  width: 80%;
}

.hero-hcp .hero-card ul {
  margin-top: 12px;
}

.hero-hcp .hero-card a {
  position: relative;
  text-decoration: underline;
}

.hero-hcp .hero-card a:hover {
  text-decoration: none;
}

.hero-hcp .hero-card a.with-chevron::after {
  content: ">";
  color: #ffd200;
  position: absolute;
  right: -12px;
  top: 12px;
  line-height: 0;
}

.hero-hcp .hero-card li {
  list-style: none;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  padding-left: 15px;
  position: relative;
}

.hero-hcp .hero-card li::before {
  content: "•";
  font-size: 31px;
  color: #ffd200;
  position: absolute;
  left: 0;
  top: 12px;
  line-height: 0;
}

.hero-hr {
  max-width: 100%;
  border-width: 8px;
  border-color: #fff1b3;
  margin: 0;
}

.breadcrumb {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  margin-left: 0;
  position: relative;
  top: -14px;
}

.breadcrumb li {
  margin-right: 16px;
  position: relative;
}

.breadcrumb li:not(:last-child):after {
  content: ">";
  position: absolute;
  right: -12px;
  top: -3px;
}

.hero-cta-bar {
  padding: 4px 0;
}

.hero-cta-bar .checkmark {
  width: 12px;
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.hero-cta-bar .cta-message {
  margin-right: 24px;
}

.footer-logo {
  width: 150px;
}

footer {
  background-color: #0099a1;
  color: #fff;
  font-size: 14px;
}

footer small {
  font-size: 12px;
  line-height: 16px;
}

footer .menu a {
  font-weight: medium;
  padding: .3rem 1rem;
}

footer .menu a:hover {
  color: #fff;
}

#isi-float {
  position: fixed;
  width: 100%;
  height: 150px;
  bottom: 0;
  left: 0;
  border-top: 1px solid #222;
  padding: 10px 0;
  background-color: #fff;
}

#isi-float p {
  line-height: 16px;
  margin-bottom: 12px;
}

#isi-float ul li {
  line-height: 16px;
}

#isi {
  padding-bottom: 60px;
}

#isi p {
  margin-bottom: 12px;
}

#isi a {
  color: #00a3ad;
  text-decoration: underline;
}

.isi-header {
  font-size: 20px;
}

.references {
  display: none;
}

.refBlock {
  font-size: 12px;
}

#references {
  background-color: #e6f6f7;
}

.helloBar {
  background-color: #00a3ad;
  color: #fff;
}

.helloBar a {
  color: #fff;
}

.sitemap a {
  color: #00a3ad;
}

.sitemap a:hover {
  text-decoration: underline;
}

.xarrow {
  cursor: pointer;
}

.wv {
  background-image: url("/images/common/patient-mb-wave.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  background-size: 100%;
}

.crm {
  padding: 3px 5px;
  background-color: #e6f6f7;
  -webkit-box-shadow: inset 0px 1px 2px rgba(0,0,0,.3);
  box-shadow: inset 0px 1px 2px rgba(0,0,0,.3);
}

.crdhrz {
  min-height: 250px;
}

.b2t {
  display: none;
}

.b2t img {
  width: 40px;
  position: fixed;
  right: 15px;
  bottom: 150px;
}

.box {
  background-color: #fff;
  padding: 15px 35px;
  border-top: 1px solid #f1f1f1;
  box-radius: 4px;
  -webkit-box-shadow: 0px -6px 10px rgba(0,0,0,.16);
  box-shadow: 0px -6px 10px rgba(0,0,0,.16);
}

.dshadow {
  -webkit-box-shadow: 0px 3px 4px rgba(0,0,0,.16) !important;
  box-shadow: 0px 3px 4px rgba(0,0,0,.16) !important;
}

.hcp section {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.lowercase {
  text-transform: lowercase !important;
}

.shadow {
  -webkit-box-shadow: inset 0px 3px 6px rgba(0,0,0,.3) !important;
  box-shadow: inset 0px 3px 6px rgba(0,0,0,.3) !important;
}

.marg-bottom {
  margin-bottom: 10px;
}

section {
  padding: 40px 0;
  -webkit-box-shadow: inset 0px 3px 6px rgba(0,0,0,.5);
  box-shadow: inset 0px 3px 6px rgba(0,0,0,.5);
}

.noshadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.videoToggle {
  padding: 10px;
  border-bottom: 1px solid #0e6e75;
  -webkit-box-shadow: 0px 1px 0px 0px #00a3ad;
  box-shadow: 0px 1px 0px 0px #00a3ad;
  cursor: pointer;
}

.videoToggle img {
  padding: 5px 0;
}

.transcript {
  padding: 10px;
  background-color: #00a3ad;
  border-radius: 4px;
  -webkit-box-shadow: inset 1px 1px 3px rgba(0,0,0,.4);
  box-shadow: inset 1px 1px 3px rgba(0,0,0,.4);
  display: none;
}

.cta-card {
  padding: 40px 20px !important;
  -webkit-box-shadow: 0px 2px 10px rgba(0,0,0,.3);
  box-shadow: 0px 2px 10px rgba(0,0,0,.3);
}

.cta-card hr {
  border-color: #ccedef;
}

.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 99;
}

.hcp-modal {
  position: fixed;
  z-index: 999;
  padding: 30px;
  background-color: #fff;
  width: 40%;
  left: 30%;
  border-radius: 4px;
  top: 15%;
  -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,.6);
  box-shadow: 0px 3px 6px rgba(0,0,0,.6);
}

.hcp-modal .close {
  color: #666;
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}

.covid-modal {
  position: fixed;
  z-index: 999;
  padding: 30px;
  background-color: #fff;
  width: 40%;
  left: 30%;
  border-radius: 4px;
  top: 15%;
  -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,.6);
  box-shadow: 0px 3px 6px rgba(0,0,0,.6);
}

.covid-modal .close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}

img,
svg {
  max-width: 100%;
}

.hero {
  border-bottom: 8px solid #ffd200;
  -webkit-box-shadow: inset 0px 2px 4px rgba(0,0,0,.4) !important;
  box-shadow: inset 0px 2px 4px rgba(0,0,0,.4) !important;
}

.hero-hcp {
  -webkit-box-shadow: inset 0px 2px 4px rgba(0,0,0,.4);
  box-shadow: inset 0px 2px 4px rgba(0,0,0,.4);
}

.bold {
  font-weight: bold;
}

.chart {
  padding: 40px;
  background-color: hsla(0,0%,100%,.9);
  -webkit-box-shadow: 0px 0px 6px rgba(0,0,0,.3);
  box-shadow: 0px 0px 6px rgba(0,0,0,.3);
  border-radius: 4px;
  margin: 10px 0;
}

.chart strong {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
}

.chart-bg {
  background-repeat: no-repeat;
  background-position: right top;
}

.faqdl {
  margin-top: 155px;
}

.video-text {
  padding: 15px;
  overflow: auto;
}

.video-text p {
  margin-bottom: 10px;
}

.top-shadow {
  -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,.16);
  box-shadow: 0px 2px 4px rgba(0,0,0,.16);
}

.hrz {
  min-height: 300px;
  position: relative;
}

.bg {
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

.bg.bg-right {
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: auto;
}

.bg.bg-left {
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
}

.bg.bg-contain {
  background-position-y: top;
  background-size: contain;
}

.hero-home {
  padding-bottom: 80px;
  min-height: 300px;
}

.sym-card {
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
}

.sym-card .card-title {
  margin-bottom: 16px;
}

.sym-card .button-teal {
  margin: 0 0 16px;
}

.styled-list>li {
  list-style: none;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  padding-left: 15px;
  position: relative;
}

.styled-list>li::before {
  content: "•";
  font-size: 31px;
  color: #192c39;
  position: absolute;
  left: 0;
  top: 12px;
  line-height: 0;
}

.styled-list.dashed>li::before {
  content: "-";
  font-size: 24px;
  color: #192c39;
  position: absolute;
  left: 0;
  top: 12px;
  line-height: 0;
}

.cta-bar p {
  margin-right: 24px;
}

.video-section .flex-video,
.video-section .responsive-embed {
  padding-bottom: 56%;
  margin-bottom: 0;
}

.video-section .video-transcript {
  padding: 12px;
  padding-bottom: 163.5%;
  margin-bottom: 0;
  height: 0;
  position: relative;
  overflow: auto;
}

a {
  position: relative;
}

.with-chevron::after {
  content: ">";
  color: #ffd200;
  position: absolute;
  right: -12px;
  top: 12px;
  line-height: 0;
}

.icon-default {
  width: 100px;
  margin: 12px;
}

.faq {
  padding: 20px 0;
}

.faq:nth-child(odd) {
  background-color: #f5f5f5;
}

.faq a {
  color: #0e6e75;
}

.faq .q {
  padding: 5px 10px 0px;
}

.faq .q:hover {
  cursor: pointer;
}

.faq .a {
  padding: 10px 10px;
  display: none;
}

.call-teal {
  padding: 5px 0;
}

.bc {
  background-color: #ccedef;
  padding: 5px 0;
  -webkit-box-shadow: 0 3px 3px rgba(25,44,57,.16);
  box-shadow: 0 3px 3px rgba(25,44,57,.16);
}

.bc p a:hover {
  text-decoration: underline !important;
}

.margbtm {
  margin-bottom: 100px;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  margin: 0 1rem;
}

.arrow span {
  top: .5rem;
  position: absolute;
  width: .75rem;
  height: .1rem;
  background-color: #00a3ad;
  display: inline-block;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.arrow span:first-of-type {
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrow span:last-of-type {
  right: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.arrow.active span:first-of-type {
  left: 4px;
}

.arrow.active span:last-of-type {
  right: 4px;
}

.hm {
  background-image: url("/images/home/pat-hero-bg.webp");
  background-position: bottom;
}

.su {
  background-image: url("/images/signup/patient-hero-6.0-signup.jpg");
  background-position: bottom;
}

.fq {
  background-image: url("/images/faq/hero-faqs.webp");
}

.oc {
  background-image: url("/images/oic/hero-whatoic.webp");
}

.sm {
  background-image: url("/images/common/wave.webp");
}

.pr {
  background-image: url("/images/resources/hero-resources.webp");
}

.ssp {
  background-image: url("/images/savings/hero-saving.webp");
}

.ts {
  background-image: url("/images/taking/hero-whats.webp");
}

.tol {
  background-image: url("/images/hcp/tolerability/tolerability.webp");
}

.hide-for-large-custom {
  display: none;
}

.show-for-large-custom {
  display: block;
}

.lbls label {
  text-align: right;
  padding-right: 10px;
}

.rep-form a {
  color: #2ea3f2;
  text-decoration: none;
}

.rep-form a:hover {
  color: #000;
}

body>img {
  display: none !important;
}

.bold-18-footnote {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
  font-size: 18px;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  -webkit-transition-property: opacity,-webkit-filter;
  transition-property: opacity,-webkit-filter;
  transition-property: opacity,filter;
  transition-property: opacity,filter,-webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: rgba(0,0,0,0);
}

.hamburger:hover {
  opacity: .7;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
  background-color: #000;
}

.hamburger-inner:after,
.hamburger-inner:before {
  display: block;
  content: "";
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner:after,
.hamburger--3dx .hamburger-inner:before {
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
}

.hamburger--3dx.is-active .hamburger-inner {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--3dx.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner:after,
.hamburger--3dx-r .hamburger-inner:before {
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
}

.hamburger--3dx-r.is-active .hamburger-inner {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner:after,
.hamburger--3dy .hamburger-inner:before {
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
}

.hamburger--3dy.is-active .hamburger-inner {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--3dy.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner:after,
.hamburger--3dy-r .hamburger-inner:before {
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
}

.hamburger--3dy-r.is-active .hamburger-inner {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner:after,
.hamburger--3dxy .hamburger-inner:before {
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
}

.hamburger--3dxy.is-active .hamburger-inner {
  -webkit-transform: rotateX(180deg) rotateY(180deg);
  transform: rotateX(180deg) rotateY(180deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--3dxy.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s,-webkit-transform .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner:after,
.hamburger--3dxy-r .hamburger-inner:before {
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotate(-180deg);
  transform: rotateX(180deg) rotateY(180deg) rotate(-180deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--3dxy-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--arrow.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt .hamburger-inner:before {
  -webkit-transition: top .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner:after {
  -webkit-transition: bottom .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: top .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: top .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: top .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transition: bottom .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: bottom .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: bottom .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: bottom .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r .hamburger-inner:before {
  -webkit-transition: top .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner:after {
  -webkit-transition: bottom .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom .1s ease .1s,-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom .1s ease .1s,transform .1s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform .1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: top .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: top .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: top .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transition: bottom .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: bottom .1s ease,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: bottom .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  transition: bottom .1s ease,transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s,-webkit-transform .1s cubic-bezier(0.895, 0.03, 0.685, 0.22) .1s;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowturn.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--boring .hamburger-inner,
.hamburger--boring .hamburger-inner:after,
.hamburger--boring .hamburger-inner:before {
  -webkit-transition-property: none;
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .13s;
  transition-delay: .13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .13s;
  transition-duration: .13s;
}

.hamburger--collapse .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,opacity .1s linear;
  transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,opacity .1s linear;
}

.hamburger--collapse .hamburger-inner:before {
  -webkit-transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transition-delay: .22s;
  transition-delay: .22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),opacity .1s linear .22s;
  transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),opacity .1s linear .22s;
  opacity: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .13s;
  transition-delay: .13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .13s;
  transition-duration: .13s;
}

.hamburger--collapse-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,opacity .1s linear;
  transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,opacity .1s linear;
}

.hamburger--collapse-r .hamburger-inner:before {
  -webkit-transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transition-delay: .22s;
  transition-delay: .22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
  transform: translate3d(0, -10px, 0) rotate(45deg);
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),opacity .1s linear .22s;
  transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),opacity .1s linear .22s;
  opacity: 0;
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .16s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .25s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .275s;
  transition-duration: .275s;
}

.hamburger--elastic .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .125s ease .275s;
  transition: opacity .125s ease .275s;
}

.hamburger--elastic .hamburger-inner:after {
  top: 20px;
  -webkit-transition: -webkit-transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transition-delay: 75ms;
  transition-delay: 75ms;
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
  transform: translate3d(0, 10px, 0) rotate(135deg);
}

.hamburger--elastic.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner:after {
  -webkit-transition-delay: 75ms;
  transition-delay: 75ms;
  -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
  transform: translate3d(0, -20px, 0) rotate(-270deg);
}

.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .275s;
  transition-duration: .275s;
}

.hamburger--elastic-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .125s ease .275s;
  transition: opacity .125s ease .275s;
}

.hamburger--elastic-r .hamburger-inner:after {
  top: 20px;
  -webkit-transition: -webkit-transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform .275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transition-delay: 75ms;
  transition-delay: 75ms;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
  transform: translate3d(0, 10px, 0) rotate(-135deg);
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  -webkit-transition-delay: 75ms;
  transition-delay: 75ms;
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
  transform: translate3d(0, -20px, 0) rotate(270deg);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  -webkit-transition: background-color .125s ease-in .175s;
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic .hamburger-inner:before {
  left: 0;
  -webkit-transition: top .05s linear .125s,left .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top .05s linear .125s,left .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,left .125s ease-in .175s;
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,left .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic .hamburger-inner:after {
  top: 10px;
  right: 0;
  -webkit-transition: top .05s linear .125s,right .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top .05s linear .125s,right .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,right .125s ease-in .175s;
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,right .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: rgba(0,0,0,0);
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  top: -80px;
  left: -80px;
  -webkit-transition: left .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: left .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: left .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: left .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
  transform: translate3d(80px, 80px, 0) rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  top: -80px;
  right: -80px;
  -webkit-transition: right .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: right .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: right .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: right .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  -webkit-transition: background-color .125s ease-in .175s;
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  left: 0;
  -webkit-transition: top .05s linear .125s,left .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top .05s linear .125s,left .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,left .125s ease-in .175s;
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,left .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r .hamburger-inner:after {
  top: 10px;
  right: 0;
  -webkit-transition: top .05s linear .125s,right .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top .05s linear .125s,right .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,right .125s ease-in .175s;
  transition: transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335),top .05s linear .125s,right .125s ease-in .175s,-webkit-transform .125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: rgba(0,0,0,0);
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  top: 80px;
  left: -80px;
  -webkit-transition: left .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: left .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: left .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: left .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  top: 80px;
  right: -80px;
  -webkit-transition: right .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: right .125s ease-out,top .05s linear .125s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: right .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  transition: right .125s ease-out,top .05s linear .125s,transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s,-webkit-transform .125s cubic-bezier(0.075, 0.82, 0.165, 1) .175s;
  -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
}

.hamburger--minus .hamburger-inner:after,
.hamburger--minus .hamburger-inner:before {
  -webkit-transition: bottom .08s ease-out 0s,top .08s ease-out 0s,opacity 0s linear;
  transition: bottom .08s ease-out 0s,top .08s ease-out 0s,opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner:after,
.hamburger--minus.is-active .hamburger-inner:before {
  -webkit-transition: bottom .08s ease-out,top .08s ease-out,opacity 0s linear .08s;
  transition: bottom .08s ease-out,top .08s ease-out,opacity 0s linear .08s;
  opacity: 0;
}

.hamburger--minus.is-active .hamburger-inner:before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner:after {
  bottom: 0;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.hamburger--slider .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

.hamburger--spin .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .22s;
  transition-duration: .22s;
}

.hamburger--spin .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .25s,opacity .1s ease-in;
  transition: top .1s ease-in .25s,opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner:after {
  -webkit-transition: bottom .1s ease-in .25s,-webkit-transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom .1s ease-in .25s,-webkit-transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transition-delay: .12s;
  transition-delay: .12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.hamburger--spin.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out,opacity .1s ease-out .12s;
  transition: top .1s ease-out,opacity .1s ease-out .12s;
  opacity: 0;
}

.hamburger--spin.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transition: bottom .1s ease-out,-webkit-transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom .1s ease-out,-webkit-transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom .1s ease-out,transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom .1s ease-out,transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s,-webkit-transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--spin-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .22s;
  transition-duration: .22s;
}

.hamburger--spin-r .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .25s,opacity .1s ease-in;
  transition: top .1s ease-in .25s,opacity .1s ease-in;
}

.hamburger--spin-r .hamburger-inner:after {
  -webkit-transition: bottom .1s ease-in .25s,-webkit-transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom .1s ease-in .25s,-webkit-transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform .22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transition-delay: .12s;
  transition-delay: .12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out,opacity .1s ease-out .12s;
  transition: top .1s ease-out,opacity .1s ease-out .12s;
  opacity: 0;
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transition: bottom .1s ease-out,-webkit-transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom .1s ease-out,-webkit-transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom .1s ease-out,transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom .1s ease-out,transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s,-webkit-transform .22s cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s linear .13s;
  transition: background-color 0s linear .13s;
}

.hamburger--spring .hamburger-inner:before {
  top: 10px;
  -webkit-transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner:after {
  top: 20px;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: .22s;
  transition-delay: .22s;
  background-color: rgba(0,0,0,0);
}

.hamburger--spring.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .13s;
  transition-duration: .13s;
}

.hamburger--spring-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,opacity 0s linear;
  transition: top .2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner:before {
  -webkit-transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top .1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) .2s,transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform .13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transition-delay: .22s;
  transition-delay: .22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),opacity 0s linear .22s;
  transition: top .2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),opacity 0s linear .22s;
  opacity: 0;
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  transition: top .1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) .15s,transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s,-webkit-transform .13s cubic-bezier(0.215, 0.61, 0.355, 1) .22s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--stand .hamburger-inner {
  -webkit-transition: background-color 0s linear 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s;
  transition: background-color 0s linear 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s;
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s,background-color 0s linear 75ms;
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s,background-color 0s linear 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s;
}

.hamburger--stand .hamburger-inner:before {
  -webkit-transition: top 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand .hamburger-inner:after {
  -webkit-transition: bottom 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transition: background-color 0s linear .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: background-color 0s linear .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s,background-color 0s linear .15s;
  transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s,background-color 0s linear .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--stand.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: top 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: top 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: top 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transition: bottom 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: bottom 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: bottom 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: bottom 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--stand-r .hamburger-inner {
  -webkit-transition: background-color 0s linear 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s;
  transition: background-color 0s linear 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s;
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s,background-color 0s linear 75ms;
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s,background-color 0s linear 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) .15s;
}

.hamburger--stand-r .hamburger-inner:before {
  -webkit-transition: top 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r .hamburger-inner:after {
  -webkit-transition: bottom 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 75ms ease-in 75ms,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 75ms ease-in 75ms,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transition: background-color 0s linear .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: background-color 0s linear .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s,background-color 0s linear .15s;
  transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s,background-color 0s linear .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: rgba(0,0,0,0);
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: top 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: top 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: top 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transition: bottom 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: bottom 75ms ease-out .1s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: bottom 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  transition: bottom 75ms ease-out .1s,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .15s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--squeeze .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
}

.hamburger--squeeze .hamburger-inner:before {
  -webkit-transition: top 75ms ease .12s,opacity 75ms ease;
  transition: top 75ms ease .12s,opacity 75ms ease;
}

.hamburger--squeeze .hamburger-inner:after {
  -webkit-transition: bottom 75ms ease .12s,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 75ms ease .12s,-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19),-webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transition-delay: .12s;
  transition-delay: .12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top 75ms ease,opacity 75ms ease .12s;
  transition: top 75ms ease,opacity 75ms ease .12s;
  opacity: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transition: bottom 75ms ease,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom 75ms ease,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom 75ms ease,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  transition: bottom 75ms ease,transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .12s,-webkit-transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) .12s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--vortex .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
}

.hamburger--vortex .hamburger-inner:after,
.hamburger--vortex .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex .hamburger-inner:before {
  -webkit-transition-property: top,opacity;
  transition-property: top,opacity;
}

.hamburger--vortex .hamburger-inner:after {
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
  transition-property: bottom,transform,-webkit-transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(765deg);
  transform: rotate(765deg);
}

.hamburger--vortex.is-active .hamburger-inner:after,
.hamburger--vortex.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
}

.hamburger--vortex-r .hamburger-inner:after,
.hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-property: top,opacity;
  transition-property: top,opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
  transition-property: bottom,transform,-webkit-transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(-765deg);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:after,
.hamburger--vortex-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger-inner {
  background-color: #192c39;
}

.hamburger-inner::before {
  background-color: #192c39;
}

.hamburger-inner::after {
  background-color: #192c39;
}

.hamburger:focus {
  outline: none;
  border: none;
}

.oic-facts {
  margin: 16px 0;
  padding: 32px 0;
}

.oic-facts .title {
  margin-bottom: 16px;
}

.oic-facts li {
  margin-bottom: 24px;
}

.oic-facts .img-wrap {
  -ms-flex-preferred-size: 12%;
  flex-basis: 12%;
  margin-right: 16px;
  height: 100%;
}

.oic-facts p {
  -ms-flex-preferred-size: 88%;
  flex-basis: 88%;
}

.cta-bar {
  padding: 16px 0;
}

.patient-savings-card .img-wrap {
  width: 275px;
}

.efficacy-page .adverse-cta-bar p {
  margin-right: 24px;
}

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media screen and (min-width: 0\0 ) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}

@media print,screen and (min-width: 40em) {
  .reveal,
  .reveal.tiny,
  .reveal.small,
  .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto;
  }

  h1,
  .h1 {
    font-size: 3rem;
  }

  h2,
  .h2 {
    font-size: 2.5rem;
  }

  h3,
  .h3 {
    font-size: 1.9375rem;
  }

  h4,
  .h4 {
    font-size: 1.5625rem;
  }

  h5,
  .h5 {
    font-size: 1.25rem;
  }

  h6,
  .h6 {
    font-size: 1rem;
  }

  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }

  .hide-for-medium {
    display: none !important;
  }

  .button-group.stacked-for-small .button {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    margin-bottom: 0;
  }

  .menu.medium-horizontal {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .menu.medium-vertical {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .menu.medium-expanded li {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
  }

  .menu.medium-simple li {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
  }

  .dropdown.menu.medium-horizontal>li.opens-left>.is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }

  .dropdown.menu.medium-horizontal>li.opens-right>.is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }

  .dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a {
    position: relative;
    padding-right: 1.5rem;
  }

  .dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
    right: 5px;
    left: auto;
    margin-top: -3px;
  }

  .dropdown.menu.medium-vertical>li .is-dropdown-submenu {
    top: 0;
  }

  .dropdown.menu.medium-vertical>li.opens-left>.is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }

  .dropdown.menu.medium-vertical>li.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 100%;
  }

  .dropdown.menu.medium-vertical>li>a::after {
    right: 14px;
  }

  .dropdown.menu.medium-vertical>li.opens-left>a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-left-width: 0;
    border-right-style: solid;
    border-color: rgba(0,0,0,0) #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
  }

  .dropdown.menu.medium-vertical>li.opens-right>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-right-width: 0;
    border-left-style: solid;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #2ba6cb;
  }

  .top-bar {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .top-bar .top-bar-left {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    margin-right: auto;
  }

  .top-bar .top-bar-right {
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    margin-left: auto;
  }

  .pagination li {
    display: inline-block;
  }

  .position-left.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-left.reveal-for-medium .close-button {
    display: none;
  }

  .off-canvas-content .position-left.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }

  .position-left.reveal-for-medium~.off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-right.reveal-for-medium .close-button {
    display: none;
  }

  .off-canvas-content .position-right.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }

  .position-right.reveal-for-medium~.off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-top.reveal-for-medium .close-button {
    display: none;
  }

  .off-canvas-content .position-top.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }

  .position-top.reveal-for-medium~.off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-bottom.reveal-for-medium .close-button {
    display: none;
  }

  .off-canvas-content .position-bottom.reveal-for-medium {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }

  .position-bottom.reveal-for-medium~.off-canvas-content {
    margin-bottom: 250px;
  }

  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    -webkit-transition: inherit;
    transition: inherit;
  }

  .off-canvas.in-canvas-for-medium.position-left,
  .off-canvas.in-canvas-for-medium.position-right,
  .off-canvas.in-canvas-for-medium.position-top,
  .off-canvas.in-canvas-for-medium.position-bottom {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas.in-canvas-for-medium .close-button {
    display: none;
  }

  .reveal {
    min-height: 0;
  }

  .reveal {
    max-width: 62.5rem;
  }

  .reveal.tiny {
    width: 30%;
    max-width: 62.5rem;
  }

  .reveal.small {
    width: 50%;
    max-width: 62.5rem;
  }

  .reveal.large {
    width: 90%;
    max-width: 62.5rem;
  }

  .grid-x>.medium-shrink,
  .grid-x>.medium-full,
  .grid-x>.medium-1,
  .grid-x>.medium-2,
  .grid-x>.medium-3,
  .grid-x>.medium-4,
  .grid-x>.medium-5,
  .grid-x>.medium-6,
  .grid-x>.medium-7,
  .grid-x>.medium-8,
  .grid-x>.medium-9,
  .grid-x>.medium-10,
  .grid-x>.medium-11,
  .grid-x>.medium-12 {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .grid-x>.medium-auto {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    width: auto;
  }

  .grid-x>.medium-shrink {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
  }

  .grid-x>.medium-1 {
    width: 8.33333%;
  }

  .grid-x>.medium-2 {
    width: 16.66667%;
  }

  .grid-x>.medium-3 {
    width: 25%;
  }

  .grid-x>.medium-4 {
    width: 33.33333%;
  }

  .grid-x>.medium-5 {
    width: 41.66667%;
  }

  .grid-x>.medium-6 {
    width: 50%;
  }

  .grid-x>.medium-7 {
    width: 58.33333%;
  }

  .grid-x>.medium-8 {
    width: 66.66667%;
  }

  .grid-x>.medium-9 {
    width: 75%;
  }

  .grid-x>.medium-10 {
    width: 83.33333%;
  }

  .grid-x>.medium-11 {
    width: 91.66667%;
  }

  .grid-x>.medium-12 {
    width: 100%;
  }

  .grid-margin-x>.medium-auto {
    width: auto;
  }

  .grid-margin-x>.medium-shrink {
    width: auto;
  }

  .grid-margin-x>.medium-1 {
    width: calc(8.33333% - 1.875rem);
  }

  .grid-margin-x>.medium-2 {
    width: calc(16.66667% - 1.875rem);
  }

  .grid-margin-x>.medium-3 {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x>.medium-4 {
    width: calc(33.33333% - 1.875rem);
  }

  .grid-margin-x>.medium-5 {
    width: calc(41.66667% - 1.875rem);
  }

  .grid-margin-x>.medium-6 {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x>.medium-7 {
    width: calc(58.33333% - 1.875rem);
  }

  .grid-margin-x>.medium-8 {
    width: calc(66.66667% - 1.875rem);
  }

  .grid-margin-x>.medium-9 {
    width: calc(75% - 1.875rem);
  }

  .grid-margin-x>.medium-10 {
    width: calc(83.33333% - 1.875rem);
  }

  .grid-margin-x>.medium-11 {
    width: calc(91.66667% - 1.875rem);
  }

  .grid-margin-x>.medium-12 {
    width: calc(100% - 1.875rem);
  }

  .medium-up-1>.cell {
    width: 100%;
  }

  .medium-up-2>.cell {
    width: 50%;
  }

  .medium-up-3>.cell {
    width: 33.33333%;
  }

  .medium-up-4>.cell {
    width: 25%;
  }

  .medium-up-5>.cell {
    width: 20%;
  }

  .medium-up-6>.cell {
    width: 16.66667%;
  }

  .medium-up-7>.cell {
    width: 14.28571%;
  }

  .medium-up-8>.cell {
    width: 12.5%;
  }

  .grid-margin-x.medium-up-1>.cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.medium-up-2>.cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.medium-up-3>.cell {
    width: calc(33.33333% - 1.875rem);
  }

  .grid-margin-x.medium-up-4>.cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.medium-up-5>.cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.medium-up-6>.cell {
    width: calc(16.66667% - 1.875rem);
  }

  .grid-margin-x.medium-up-7>.cell {
    width: calc(14.28571% - 1.875rem);
  }

  .grid-margin-x.medium-up-8>.cell {
    width: calc(12.5% - 1.875rem);
  }

  .small-margin-collapse>.medium-1 {
    width: 8.33333%;
  }

  .small-margin-collapse>.medium-2 {
    width: 16.66667%;
  }

  .small-margin-collapse>.medium-3 {
    width: 25%;
  }

  .small-margin-collapse>.medium-4 {
    width: 33.33333%;
  }

  .small-margin-collapse>.medium-5 {
    width: 41.66667%;
  }

  .small-margin-collapse>.medium-6 {
    width: 50%;
  }

  .small-margin-collapse>.medium-7 {
    width: 58.33333%;
  }

  .small-margin-collapse>.medium-8 {
    width: 66.66667%;
  }

  .small-margin-collapse>.medium-9 {
    width: 75%;
  }

  .small-margin-collapse>.medium-10 {
    width: 83.33333%;
  }

  .small-margin-collapse>.medium-11 {
    width: 91.66667%;
  }

  .small-margin-collapse>.medium-12 {
    width: 100%;
  }

  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .medium-margin-collapse>.cell {
    margin-right: 0;
    margin-left: 0;
  }

  .medium-margin-collapse>.small-1 {
    width: 8.33333%;
  }

  .medium-margin-collapse>.small-2 {
    width: 16.66667%;
  }

  .medium-margin-collapse>.small-3 {
    width: 25%;
  }

  .medium-margin-collapse>.small-4 {
    width: 33.33333%;
  }

  .medium-margin-collapse>.small-5 {
    width: 41.66667%;
  }

  .medium-margin-collapse>.small-6 {
    width: 50%;
  }

  .medium-margin-collapse>.small-7 {
    width: 58.33333%;
  }

  .medium-margin-collapse>.small-8 {
    width: 66.66667%;
  }

  .medium-margin-collapse>.small-9 {
    width: 75%;
  }

  .medium-margin-collapse>.small-10 {
    width: 83.33333%;
  }

  .medium-margin-collapse>.small-11 {
    width: 91.66667%;
  }

  .medium-margin-collapse>.small-12 {
    width: 100%;
  }

  .medium-margin-collapse>.medium-1 {
    width: 8.33333%;
  }

  .medium-margin-collapse>.medium-2 {
    width: 16.66667%;
  }

  .medium-margin-collapse>.medium-3 {
    width: 25%;
  }

  .medium-margin-collapse>.medium-4 {
    width: 33.33333%;
  }

  .medium-margin-collapse>.medium-5 {
    width: 41.66667%;
  }

  .medium-margin-collapse>.medium-6 {
    width: 50%;
  }

  .medium-margin-collapse>.medium-7 {
    width: 58.33333%;
  }

  .medium-margin-collapse>.medium-8 {
    width: 66.66667%;
  }

  .medium-margin-collapse>.medium-9 {
    width: 75%;
  }

  .medium-margin-collapse>.medium-10 {
    width: 83.33333%;
  }

  .medium-margin-collapse>.medium-11 {
    width: 91.66667%;
  }

  .medium-margin-collapse>.medium-12 {
    width: 100%;
  }

  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .medium-padding-collapse>.cell {
    padding-right: 0;
    padding-left: 0;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x>.medium-offset-0 {
    margin-left: calc(0% + .9375rem);
  }

  .medium-offset-1 {
    margin-left: 8.33333%;
  }

  .grid-margin-x>.medium-offset-1 {
    margin-left: calc(8.33333% + .9375rem);
  }

  .medium-offset-2 {
    margin-left: 16.66667%;
  }

  .grid-margin-x>.medium-offset-2 {
    margin-left: calc(16.66667% + .9375rem);
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x>.medium-offset-3 {
    margin-left: calc(25% + .9375rem);
  }

  .medium-offset-4 {
    margin-left: 33.33333%;
  }

  .grid-margin-x>.medium-offset-4 {
    margin-left: calc(33.33333% + .9375rem);
  }

  .medium-offset-5 {
    margin-left: 41.66667%;
  }

  .grid-margin-x>.medium-offset-5 {
    margin-left: calc(41.66667% + .9375rem);
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x>.medium-offset-6 {
    margin-left: calc(50% + .9375rem);
  }

  .medium-offset-7 {
    margin-left: 58.33333%;
  }

  .grid-margin-x>.medium-offset-7 {
    margin-left: calc(58.33333% + .9375rem);
  }

  .medium-offset-8 {
    margin-left: 66.66667%;
  }

  .grid-margin-x>.medium-offset-8 {
    margin-left: calc(66.66667% + .9375rem);
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x>.medium-offset-9 {
    margin-left: calc(75% + .9375rem);
  }

  .medium-offset-10 {
    margin-left: 83.33333%;
  }

  .grid-margin-x>.medium-offset-10 {
    margin-left: calc(83.33333% + .9375rem);
  }

  .medium-offset-11 {
    margin-left: 91.66667%;
  }

  .grid-margin-x>.medium-offset-11 {
    margin-left: calc(91.66667% + .9375rem);
  }

  .grid-y>.medium-shrink,
  .grid-y>.medium-full,
  .grid-y>.medium-1,
  .grid-y>.medium-2,
  .grid-y>.medium-3,
  .grid-y>.medium-4,
  .grid-y>.medium-5,
  .grid-y>.medium-6,
  .grid-y>.medium-7,
  .grid-y>.medium-8,
  .grid-y>.medium-9,
  .grid-y>.medium-10,
  .grid-y>.medium-11,
  .grid-y>.medium-12 {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .grid-y>.medium-auto {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    height: auto;
  }

  .grid-y>.medium-shrink {
    height: auto;
  }

  .grid-y>.medium-1 {
    height: 8.33333%;
  }

  .grid-y>.medium-2 {
    height: 16.66667%;
  }

  .grid-y>.medium-3 {
    height: 25%;
  }

  .grid-y>.medium-4 {
    height: 33.33333%;
  }

  .grid-y>.medium-5 {
    height: 41.66667%;
  }

  .grid-y>.medium-6 {
    height: 50%;
  }

  .grid-y>.medium-7 {
    height: 58.33333%;
  }

  .grid-y>.medium-8 {
    height: 66.66667%;
  }

  .grid-y>.medium-9 {
    height: 75%;
  }

  .grid-y>.medium-10 {
    height: 83.33333%;
  }

  .grid-y>.medium-11 {
    height: 91.66667%;
  }

  .grid-y>.medium-12 {
    height: 100%;
  }

  .grid-margin-y>.medium-auto {
    height: auto;
  }

  .grid-margin-y>.medium-shrink {
    height: auto;
  }

  .grid-margin-y>.medium-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-2 {
    height: calc(16.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y>.medium-4 {
    height: calc(33.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-5 {
    height: calc(41.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y>.medium-7 {
    height: calc(58.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-8 {
    height: calc(66.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y>.medium-10 {
    height: calc(83.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-11 {
    height: calc(91.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-12 {
    height: calc(100% - 1.875rem);
  }

  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100vw;
  }

  .cell .medium-grid-frame {
    width: 100%;
  }

  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }

  .medium-cell-block-container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-height: 100%;
  }

  .medium-cell-block-container>.grid-x {
    max-height: 100%;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }

  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
    height: 100vh;
  }

  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }

  .grid-margin-y>.medium-auto {
    height: auto;
  }

  .grid-margin-y>.medium-shrink {
    height: auto;
  }

  .grid-margin-y>.medium-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-2 {
    height: calc(16.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y>.medium-4 {
    height: calc(33.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-5 {
    height: calc(41.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y>.medium-7 {
    height: calc(58.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-8 {
    height: calc(66.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y>.medium-10 {
    height: calc(83.33333% - 1.875rem);
  }

  .grid-margin-y>.medium-11 {
    height: calc(91.66667% - 1.875rem);
  }

  .grid-margin-y>.medium-12 {
    height: calc(100% - 1.875rem);
  }

  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem);
  }

  .medium-order-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .medium-order-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .medium-order-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .medium-order-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .medium-order-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .medium-order-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .medium-flex-container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
  }

  .medium-flex-child-auto {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
  }

  .medium-flex-child-grow {
    -ms-flex: 1 0 auto;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
  }

  .medium-flex-child-shrink {
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
  }

  .medium-flex-dir-row {
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .medium-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
  }

  .medium-flex-dir-column {
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .medium-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

@media print,screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }

  .hide-for-large {
    display: none !important;
  }

  .button-group.stacked-for-medium .button {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    margin-bottom: 0;
  }

  .menu.large-horizontal {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .menu.large-vertical {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .menu.large-expanded li {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
  }

  .menu.large-simple li {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
  }

  .dropdown.menu.large-horizontal>li.opens-left>.is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }

  .dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }

  .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a {
    position: relative;
    padding-right: 1.5rem;
  }

  .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #888 rgba(0,0,0,0) rgba(0,0,0,0);
    right: 5px;
    left: auto;
    margin-top: -3px;
  }

  .dropdown.menu.large-vertical>li .is-dropdown-submenu {
    top: 0;
  }

  .dropdown.menu.large-vertical>li.opens-left>.is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }

  .dropdown.menu.large-vertical>li.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 100%;
  }

  .dropdown.menu.large-vertical>li>a::after {
    right: 14px;
  }

  .dropdown.menu.large-vertical>li.opens-left>a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-left-width: 0;
    border-right-style: solid;
    border-color: rgba(0,0,0,0) #2ba6cb rgba(0,0,0,0) rgba(0,0,0,0);
  }

  .dropdown.menu.large-vertical>li.opens-right>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-right-width: 0;
    border-left-style: solid;
    border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #2ba6cb;
  }

  .position-left.reveal-for-large {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-left.reveal-for-large .close-button {
    display: none;
  }

  .off-canvas-content .position-left.reveal-for-large {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }

  .position-left.reveal-for-large~.off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-large {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-right.reveal-for-large .close-button {
    display: none;
  }

  .off-canvas-content .position-right.reveal-for-large {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }

  .position-right.reveal-for-large~.off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-large {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-top.reveal-for-large .close-button {
    display: none;
  }

  .off-canvas-content .position-top.reveal-for-large {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }

  .position-top.reveal-for-large~.off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-large {
    -webkit-transform: none;
    transform: none;
    z-index: 12;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }

  .position-bottom.reveal-for-large .close-button {
    display: none;
  }

  .off-canvas-content .position-bottom.reveal-for-large {
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }

  .position-bottom.reveal-for-large~.off-canvas-content {
    margin-bottom: 250px;
  }

  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    -webkit-transition: inherit;
    transition: inherit;
  }

  .off-canvas.in-canvas-for-large.position-left,
  .off-canvas.in-canvas-for-large.position-right,
  .off-canvas.in-canvas-for-large.position-top,
  .off-canvas.in-canvas-for-large.position-bottom {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .off-canvas.in-canvas-for-large .close-button {
    display: none;
  }

  .grid-x>.large-shrink,
  .grid-x>.large-full,
  .grid-x>.large-1,
  .grid-x>.large-2,
  .grid-x>.large-3,
  .grid-x>.large-4,
  .grid-x>.large-5,
  .grid-x>.large-6,
  .grid-x>.large-7,
  .grid-x>.large-8,
  .grid-x>.large-9,
  .grid-x>.large-10,
  .grid-x>.large-11,
  .grid-x>.large-12 {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .grid-x>.large-auto {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    width: auto;
  }

  .grid-x>.large-shrink {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
  }

  .grid-x>.large-1 {
    width: 8.33333%;
  }

  .grid-x>.large-2 {
    width: 16.66667%;
  }

  .grid-x>.large-3 {
    width: 25%;
  }

  .grid-x>.large-4 {
    width: 33.33333%;
  }

  .grid-x>.large-5 {
    width: 41.66667%;
  }

  .grid-x>.large-6 {
    width: 50%;
  }

  .grid-x>.large-7 {
    width: 58.33333%;
  }

  .grid-x>.large-8 {
    width: 66.66667%;
  }

  .grid-x>.large-9 {
    width: 75%;
  }

  .grid-x>.large-10 {
    width: 83.33333%;
  }

  .grid-x>.large-11 {
    width: 91.66667%;
  }

  .grid-x>.large-12 {
    width: 100%;
  }

  .grid-margin-x>.large-auto {
    width: auto;
  }

  .grid-margin-x>.large-shrink {
    width: auto;
  }

  .grid-margin-x>.large-1 {
    width: calc(8.33333% - 1.875rem);
  }

  .grid-margin-x>.large-2 {
    width: calc(16.66667% - 1.875rem);
  }

  .grid-margin-x>.large-3 {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x>.large-4 {
    width: calc(33.33333% - 1.875rem);
  }

  .grid-margin-x>.large-5 {
    width: calc(41.66667% - 1.875rem);
  }

  .grid-margin-x>.large-6 {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x>.large-7 {
    width: calc(58.33333% - 1.875rem);
  }

  .grid-margin-x>.large-8 {
    width: calc(66.66667% - 1.875rem);
  }

  .grid-margin-x>.large-9 {
    width: calc(75% - 1.875rem);
  }

  .grid-margin-x>.large-10 {
    width: calc(83.33333% - 1.875rem);
  }

  .grid-margin-x>.large-11 {
    width: calc(91.66667% - 1.875rem);
  }

  .grid-margin-x>.large-12 {
    width: calc(100% - 1.875rem);
  }

  .large-up-1>.cell {
    width: 100%;
  }

  .large-up-2>.cell {
    width: 50%;
  }

  .large-up-3>.cell {
    width: 33.33333%;
  }

  .large-up-4>.cell {
    width: 25%;
  }

  .large-up-5>.cell {
    width: 20%;
  }

  .large-up-6>.cell {
    width: 16.66667%;
  }

  .large-up-7>.cell {
    width: 14.28571%;
  }

  .large-up-8>.cell {
    width: 12.5%;
  }

  .grid-margin-x.large-up-1>.cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.large-up-2>.cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.large-up-3>.cell {
    width: calc(33.33333% - 1.875rem);
  }

  .grid-margin-x.large-up-4>.cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.large-up-5>.cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.large-up-6>.cell {
    width: calc(16.66667% - 1.875rem);
  }

  .grid-margin-x.large-up-7>.cell {
    width: calc(14.28571% - 1.875rem);
  }

  .grid-margin-x.large-up-8>.cell {
    width: calc(12.5% - 1.875rem);
  }

  .small-margin-collapse>.large-1 {
    width: 8.33333%;
  }

  .small-margin-collapse>.large-2 {
    width: 16.66667%;
  }

  .small-margin-collapse>.large-3 {
    width: 25%;
  }

  .small-margin-collapse>.large-4 {
    width: 33.33333%;
  }

  .small-margin-collapse>.large-5 {
    width: 41.66667%;
  }

  .small-margin-collapse>.large-6 {
    width: 50%;
  }

  .small-margin-collapse>.large-7 {
    width: 58.33333%;
  }

  .small-margin-collapse>.large-8 {
    width: 66.66667%;
  }

  .small-margin-collapse>.large-9 {
    width: 75%;
  }

  .small-margin-collapse>.large-10 {
    width: 83.33333%;
  }

  .small-margin-collapse>.large-11 {
    width: 91.66667%;
  }

  .small-margin-collapse>.large-12 {
    width: 100%;
  }

  .medium-margin-collapse>.large-1 {
    width: 8.33333%;
  }

  .medium-margin-collapse>.large-2 {
    width: 16.66667%;
  }

  .medium-margin-collapse>.large-3 {
    width: 25%;
  }

  .medium-margin-collapse>.large-4 {
    width: 33.33333%;
  }

  .medium-margin-collapse>.large-5 {
    width: 41.66667%;
  }

  .medium-margin-collapse>.large-6 {
    width: 50%;
  }

  .medium-margin-collapse>.large-7 {
    width: 58.33333%;
  }

  .medium-margin-collapse>.large-8 {
    width: 66.66667%;
  }

  .medium-margin-collapse>.large-9 {
    width: 75%;
  }

  .medium-margin-collapse>.large-10 {
    width: 83.33333%;
  }

  .medium-margin-collapse>.large-11 {
    width: 91.66667%;
  }

  .medium-margin-collapse>.large-12 {
    width: 100%;
  }

  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .large-margin-collapse>.cell {
    margin-right: 0;
    margin-left: 0;
  }

  .large-margin-collapse>.small-1 {
    width: 8.33333%;
  }

  .large-margin-collapse>.small-2 {
    width: 16.66667%;
  }

  .large-margin-collapse>.small-3 {
    width: 25%;
  }

  .large-margin-collapse>.small-4 {
    width: 33.33333%;
  }

  .large-margin-collapse>.small-5 {
    width: 41.66667%;
  }

  .large-margin-collapse>.small-6 {
    width: 50%;
  }

  .large-margin-collapse>.small-7 {
    width: 58.33333%;
  }

  .large-margin-collapse>.small-8 {
    width: 66.66667%;
  }

  .large-margin-collapse>.small-9 {
    width: 75%;
  }

  .large-margin-collapse>.small-10 {
    width: 83.33333%;
  }

  .large-margin-collapse>.small-11 {
    width: 91.66667%;
  }

  .large-margin-collapse>.small-12 {
    width: 100%;
  }

  .large-margin-collapse>.medium-1 {
    width: 8.33333%;
  }

  .large-margin-collapse>.medium-2 {
    width: 16.66667%;
  }

  .large-margin-collapse>.medium-3 {
    width: 25%;
  }

  .large-margin-collapse>.medium-4 {
    width: 33.33333%;
  }

  .large-margin-collapse>.medium-5 {
    width: 41.66667%;
  }

  .large-margin-collapse>.medium-6 {
    width: 50%;
  }

  .large-margin-collapse>.medium-7 {
    width: 58.33333%;
  }

  .large-margin-collapse>.medium-8 {
    width: 66.66667%;
  }

  .large-margin-collapse>.medium-9 {
    width: 75%;
  }

  .large-margin-collapse>.medium-10 {
    width: 83.33333%;
  }

  .large-margin-collapse>.medium-11 {
    width: 91.66667%;
  }

  .large-margin-collapse>.medium-12 {
    width: 100%;
  }

  .large-margin-collapse>.large-1 {
    width: 8.33333%;
  }

  .large-margin-collapse>.large-2 {
    width: 16.66667%;
  }

  .large-margin-collapse>.large-3 {
    width: 25%;
  }

  .large-margin-collapse>.large-4 {
    width: 33.33333%;
  }

  .large-margin-collapse>.large-5 {
    width: 41.66667%;
  }

  .large-margin-collapse>.large-6 {
    width: 50%;
  }

  .large-margin-collapse>.large-7 {
    width: 58.33333%;
  }

  .large-margin-collapse>.large-8 {
    width: 66.66667%;
  }

  .large-margin-collapse>.large-9 {
    width: 75%;
  }

  .large-margin-collapse>.large-10 {
    width: 83.33333%;
  }

  .large-margin-collapse>.large-11 {
    width: 91.66667%;
  }

  .large-margin-collapse>.large-12 {
    width: 100%;
  }

  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .large-padding-collapse>.cell {
    padding-right: 0;
    padding-left: 0;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x>.large-offset-0 {
    margin-left: calc(0% + .9375rem);
  }

  .large-offset-1 {
    margin-left: 8.33333%;
  }

  .grid-margin-x>.large-offset-1 {
    margin-left: calc(8.33333% + .9375rem);
  }

  .large-offset-2 {
    margin-left: 16.66667%;
  }

  .grid-margin-x>.large-offset-2 {
    margin-left: calc(16.66667% + .9375rem);
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x>.large-offset-3 {
    margin-left: calc(25% + .9375rem);
  }

  .large-offset-4 {
    margin-left: 33.33333%;
  }

  .grid-margin-x>.large-offset-4 {
    margin-left: calc(33.33333% + .9375rem);
  }

  .large-offset-5 {
    margin-left: 41.66667%;
  }

  .grid-margin-x>.large-offset-5 {
    margin-left: calc(41.66667% + .9375rem);
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x>.large-offset-6 {
    margin-left: calc(50% + .9375rem);
  }

  .large-offset-7 {
    margin-left: 58.33333%;
  }

  .grid-margin-x>.large-offset-7 {
    margin-left: calc(58.33333% + .9375rem);
  }

  .large-offset-8 {
    margin-left: 66.66667%;
  }

  .grid-margin-x>.large-offset-8 {
    margin-left: calc(66.66667% + .9375rem);
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x>.large-offset-9 {
    margin-left: calc(75% + .9375rem);
  }

  .large-offset-10 {
    margin-left: 83.33333%;
  }

  .grid-margin-x>.large-offset-10 {
    margin-left: calc(83.33333% + .9375rem);
  }

  .large-offset-11 {
    margin-left: 91.66667%;
  }

  .grid-margin-x>.large-offset-11 {
    margin-left: calc(91.66667% + .9375rem);
  }

  .grid-y>.large-shrink,
  .grid-y>.large-full,
  .grid-y>.large-1,
  .grid-y>.large-2,
  .grid-y>.large-3,
  .grid-y>.large-4,
  .grid-y>.large-5,
  .grid-y>.large-6,
  .grid-y>.large-7,
  .grid-y>.large-8,
  .grid-y>.large-9,
  .grid-y>.large-10,
  .grid-y>.large-11,
  .grid-y>.large-12 {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .grid-y>.large-auto {
    -ms-flex: 1 1 0px;
    -webkit-box-flex: 1;
    flex: 1 1 0px;
    height: auto;
  }

  .grid-y>.large-shrink {
    height: auto;
  }

  .grid-y>.large-1 {
    height: 8.33333%;
  }

  .grid-y>.large-2 {
    height: 16.66667%;
  }

  .grid-y>.large-3 {
    height: 25%;
  }

  .grid-y>.large-4 {
    height: 33.33333%;
  }

  .grid-y>.large-5 {
    height: 41.66667%;
  }

  .grid-y>.large-6 {
    height: 50%;
  }

  .grid-y>.large-7 {
    height: 58.33333%;
  }

  .grid-y>.large-8 {
    height: 66.66667%;
  }

  .grid-y>.large-9 {
    height: 75%;
  }

  .grid-y>.large-10 {
    height: 83.33333%;
  }

  .grid-y>.large-11 {
    height: 91.66667%;
  }

  .grid-y>.large-12 {
    height: 100%;
  }

  .grid-margin-y>.large-auto {
    height: auto;
  }

  .grid-margin-y>.large-shrink {
    height: auto;
  }

  .grid-margin-y>.large-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y>.large-2 {
    height: calc(16.66667% - 1.875rem);
  }

  .grid-margin-y>.large-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y>.large-4 {
    height: calc(33.33333% - 1.875rem);
  }

  .grid-margin-y>.large-5 {
    height: calc(41.66667% - 1.875rem);
  }

  .grid-margin-y>.large-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y>.large-7 {
    height: calc(58.33333% - 1.875rem);
  }

  .grid-margin-y>.large-8 {
    height: calc(66.66667% - 1.875rem);
  }

  .grid-margin-y>.large-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y>.large-10 {
    height: calc(83.33333% - 1.875rem);
  }

  .grid-margin-y>.large-11 {
    height: calc(91.66667% - 1.875rem);
  }

  .grid-margin-y>.large-12 {
    height: calc(100% - 1.875rem);
  }

  .large-grid-frame {
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100vw;
  }

  .cell .large-grid-frame {
    width: 100%;
  }

  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }

  .large-cell-block-container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-height: 100%;
  }

  .large-cell-block-container>.grid-x {
    max-height: 100%;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }

  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
    height: 100vh;
  }

  .cell .grid-y.large-grid-frame {
    height: 100%;
  }

  .grid-margin-y>.large-auto {
    height: auto;
  }

  .grid-margin-y>.large-shrink {
    height: auto;
  }

  .grid-margin-y>.large-1 {
    height: calc(8.33333% - 1.875rem);
  }

  .grid-margin-y>.large-2 {
    height: calc(16.66667% - 1.875rem);
  }

  .grid-margin-y>.large-3 {
    height: calc(25% - 1.875rem);
  }

  .grid-margin-y>.large-4 {
    height: calc(33.33333% - 1.875rem);
  }

  .grid-margin-y>.large-5 {
    height: calc(41.66667% - 1.875rem);
  }

  .grid-margin-y>.large-6 {
    height: calc(50% - 1.875rem);
  }

  .grid-margin-y>.large-7 {
    height: calc(58.33333% - 1.875rem);
  }

  .grid-margin-y>.large-8 {
    height: calc(66.66667% - 1.875rem);
  }

  .grid-margin-y>.large-9 {
    height: calc(75% - 1.875rem);
  }

  .grid-margin-y>.large-10 {
    height: calc(83.33333% - 1.875rem);
  }

  .grid-margin-y>.large-11 {
    height: calc(91.66667% - 1.875rem);
  }

  .grid-margin-y>.large-12 {
    height: calc(100% - 1.875rem);
  }

  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem);
  }

  .large-order-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .large-order-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .large-order-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .large-order-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .large-order-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .large-order-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .large-flex-container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
  }

  .large-flex-child-auto {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
  }

  .large-flex-child-grow {
    -ms-flex: 1 0 auto;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
  }

  .large-flex-child-shrink {
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
  }

  .large-flex-dir-row {
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .large-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
  }

  .large-flex-dir-column {
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .large-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

@media (min-width: 1024px) {
  .tol {
    background-image: url("/images/hcp/tolerability/tolerability.webp");
  }
}

@media (max-width: 1480px) {
  .hero-hcp {
    background-position: 20% top;
  }
}

@media (max-width: 1364px) {
  .oc {
    background-position: 50% top;
  }

  .sm {
    background-position: 45% bottom;
    background-size: auto;
  }

  .ts {
    background-position: 45% top;
  }
}

@media (max-width: 1260px) {
  .hcphrz {
    background-position: 80% top;
  }
}

@media (max-width: 1199px) {
  .oc {
    background-position: 65% top;
  }

  .sm {
    background-position: 65% bottom !important;
    background-size: auto !important;
  }

  .ts {
    background-position: 65% top;
  }
}

@media (max-width: 1124px) {
  .patient nav ul li .nav-item {
    font-size: 14px;
  }
}

@media (max-width: 1072px) {
  .hero {
    border-bottom: none;
    -webkit-box-shadow: inset 0px 2px 4px rgba(0,0,0,.4) !important;
    box-shadow: inset 0px 2px 4px rgba(0,0,0,.4) !important;
  }

  .rem-abs-mob {
    position: unset !important;
  }
}

@media (max-width: 1025px) {
  .hide-for-large-custom {
    display: block;
  }

  .show-for-large-custom {
    display: none;
  }
}

@media (max-width: 1024px) {
  .crm {
    top: 0;
    left: 0;
    width: 100%;
  }

  .hcp-modal {
    width: 60%;
    left: 20%;
  }

  .covid-modal {
    width: 60%;
    left: 20%;
  }

  .chart {
    margin: 30px 0;
  }

  .faqdl {
    margin-top: 60px;
  }

  .hm {
    background-image: url("/images/home/patient-0.0-mb-image1.jpg");
    background-position: top;
  }

  .su {
    background-image: url("/images/signup/patient-hero-6.0-signup.jpg");
    background-position: top right;
  }

  .fq {
    background-image: url("/images/faq/mb-faqs.webp");
    background-position: top;
  }

  .oc {
    background-image: url("/images/oic/mb-whatoic.webp");
    background-position: top !important;
  }

  .pr {
    background-image: url("/images/resources/mb-resources.webp");
    background-position: top;
  }

  .ssp {
    background-image: url("/images/savings/mb-saving.webp");
    background-position: top;
  }

  .ts {
    background-image: url("/images/taking/mb-whats.webp");
    background-position: top;
  }
}

@media (max-width: 1023px) {
  .sitemap {
    padding: 0 !important;
  }
}

@media (max-width: 900px) {
  .borrad10 {
    border-radius: 10px !important;
  }

  .nopadbottom {
    padding-bottom: 0 !important;
  }

  .nomargbottom {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 810px) {
  .hcp-modal {
    width: 80%;
    left: 10%;
  }

  .covid-modal {
    width: 80%;
    left: 10%;
  }
}

@media (max-width: 767px) {
  .margbtm {
    margin-bottom: 20px;
  }

  .margtop {
    margin-top: 100px;
  }

  .chart10marg {
    padding: 10px !important;
  }

  .hide-bg-img-mobile {
    background-image: none !important;
  }

  #oic-img {
    background-position: 0% !important;
  }

  #what-img {
    background-position: 0% !important;
  }

  .ssp {
    background-position: -210px 0 !important;
  }

  .margintop30 {
    margin-top: 30px;
  }

  .hcpres {
    background-position: 90% 0 !important;
  }

  .hide-mob {
    display: none;
  }

  .hcp-home-card {
    margin-top: 30px;
  }

  .padtop20 {
    padding-top: 20px;
  }

  .chart {
    margin-top: 0;
  }

  .margtop30 {
    margin-top: 30px !important;
  }

  .margbottom30 {
    margin-bottom: 30px !important;
  }

  .sm {
    background-image: url("/images/common/mb-wave.webp");
    background-size: auto !important;
  }
}

@media (max-width: 640px) {
  h1,
  .h1 {
    line-height: 34px;
  }

  .hcp-modal {
    width: 100%;
    left: 0%;
  }

  .covid-modal {
    width: 95%;
    left: 2px;
  }

  .hero-hcp {
    background-position: 60% top;
  }

  .chart {
    margin-bottom: 0;
  }

  .faqdl {
    margin-top: 20px;
  }

  .smple,
  .rp {
    background-position: 100% top;
  }
}

@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }

  .top-bar.stacked-for-medium {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  table.stack thead {
    display: none;
  }

  table.stack tfoot {
    display: none;
  }

  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }

  table.stack td {
    border-top: 0;
  }
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }

  .show-for-medium {
    display: none !important;
  }

  .button-group.stacked-for-small.expanded {
    display: block;
  }

  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }

  .media-object.stack-for-small {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }

  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 0em),screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media screen and (min-width: 40em)and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em),screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media screen and (min-width: 64em)and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em),screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

@media (min-width: 768px)and (max-width: 800px) {
  #oic-img {
    background-position: 80% !important;
  }

  #what-img {
    background-position: 95% !important;
  }
}

@media print {
  * {
    background: rgba(0,0,0,0) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print {
    display: table-cell !important;
  }

  th.show-for-print {
    display: table-cell !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

@page {
    margin: .5cm;
}

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .print-break-inside {
    page-break-inside: auto;
  }
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }

  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }

  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

@media (print),(prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
.footer-logo {
  width: 150px;
}

footer {
  background-color: #0099a1;
  color: #fff;
  font-size: 14px;
}

footer small {
  font-size: 12px;
  line-height: 16px;
}

footer .menu a {
  font-weight: medium;
  padding: .3rem 1rem;
}

footer .menu a:hover {
  color: #fff;
}