.footer-logo {
  width: 150px;
}

footer {
  background-color: #0099a1;
  color: white;
  font-size: 14px;
}

footer small {
  font-size: 12px;
  line-height: 16px;
}

footer .menu a {
  font-weight: medium;
  padding: 0.3rem 1rem;
  &:hover {
    color: white;
  }
}
